<template>
  <div>
    <Steps :stepNum="4" />
    <Card5 />
  </div>
</template>

<script>
import Card5 from "@/components/product/Card5.vue";
import Steps from "@/components/product/Steps.vue";
export default {
  components: {
    Steps,
    Card5,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>