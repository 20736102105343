<template>
  <div class="wrap">
    <el-steps
      :space="300"
      :active="stepNum"
      finish-status="success"
      process-status='success'

      :align-center="true"
    >
      <el-step title="选择产品"></el-step>
      <el-step title="投保须知"></el-step>
      <el-step title="保费试算"></el-step>
      <el-step title="投保单确认"></el-step>
      <el-step title="成功"></el-step>
    </el-steps>
  </div>
</template>

<script>
export default {
  props:['stepNum'],
  data() {
    return {
    };
  },
};
</script>

<style scoped>
.wrap {
  width: 1200px;
  margin: 30px auto;
}
</style>