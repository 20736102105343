<template>
  <div>
    <el-card class="box-card">
      <div class="head">
        <div
          style="
            font-size: 40px;
            color: #67c23a;
            margin-top: 18px;
            margin-right: 20px;
          "
        >
          <i class="el-icon-success"></i>
        </div>
        <div class="right">
          <h3 style="color: #67c23a; font-size: 20px; margin-bottom: 2px">
            订单提交成功，请您尽快付款
          </h3>
          <dl style="display: flex; flex-wrap: nowrap">
            <dt style="margin-left: 0">订单编号：</dt>
            <dd>{{ orderInfo.orderNo }}</dd>
            |
            <dt>应付金额：</dt>
            <dd style="color: red">￥{{ orderInfo.sum }}元</dd>
          </dl>
          <p style="color: #e6a23c">请以复核后的保险金额为准，并进行缴纳！</p>
        </div>
      </div>

      <div style="display: flex; flex-wrap: nowrap" class="p1">
        <dl style="display: flex; flex-wrap: nowrap">
          <dt>投保单号：</dt>
          <dd>{{ orderInfo.insuranceNo }}</dd>
        </dl>
        <dl style="display: flex; flex-wrap: nowrap">
          <dt>本保险的出单公司：</dt>
          <dd>{{ computedIssueCompany(orderInfo.issueCompany) }}</dd>
        </dl>
      </div>

      <el-divider></el-divider>
      <div style="margin-left: 60px">
        <p>转帐帐号信息如下：</p>

        <el-table :data="tableData" style="width: 100%; margin-bottom: 20px">
          <el-table-column prop="oprnAccountBank" label="开户行">
          </el-table-column>
          <el-table-column prop="openAccountName" label="开户名">
          </el-table-column>
          <el-table-column prop="bankAccount" label="银行账户" width="200">
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-left: 60px">
        <el-button type="primary" @click="openDialog1">查看订单详情</el-button>
        <el-button @click="jumpTo('/')">返回首页</el-button>
      </div>

      <el-divider></el-divider>
      <p style="text-indent: 2em">
        为保证保险人在拟定的保险起期开始承担保险责任，请您在保险起期前5个工作日办理投保手续、提交投保资料并完成保费的支付；保险费到达主承保公司
        保险费专收账户隔日（即第三日）零时，保险公司签发正式保单。如您未能按时办理投保手续或保险费未能按时到账，导致保险合同未在拟定的保险起期生效，发生保险事故，保险人不承担保险责任。
      </p>
    </el-card>

    <Dialog1
      :isShow="isShow"
      :orderId="orderInfo.orderNo"
      @toParent="getChildValue($event)"
    />
  </div>
</template>

<script>
import Dialog1 from "./Dialog1";
import func from "../../common/func.js";
export default {
  components: {
    Dialog1,
  },
  mounted() {},
  data() {
    return {
      orderInfo: {
        id: "11",
        orderNo: "22",
        sum: "233747.84",
        payMethod: "转账支付",
        insuranceNo: "T2108121634203641094", //投保单号
        issueCompany: "中国人民财产保险股份有限公司广西壮族自治区分公司", //出单公司
      },
      tableData: [
        {
          oprnAccountBank: "", //开户行
          openAccountName: "", //开户名
          bankAccount: "", //银行账户
        },
      ],
      isShow: false,
    };
  },
  methods: {
    openDialog1() {
      this.isShow = true;
    },
    getChildValue(e) {
      this.isShow = e;
    },
    jumpTo(n) {
      func.jumpTo(n, this);
    },
  },
  watch: {
    "$store.state.submitObj": {
      handler(n, o) {
     

        this.orderInfo.orderNo = n.order_no;

        if (n.order_no) {
          this.axios({
            url: "/api/v1/order/orderDetail",
            method: "post",
            data: {
              order_no: n.order_no,
            },
          }).then((res) => {
         

            if (res.data.result.bank_info) {
              this.tableData = [
                {
                  oprnAccountBank: res.data.result.bank_info.bank_name, //开户行
                  openAccountName: res.data.result.bank_info.account_name, //开户名
                  bankAccount: res.data.result.bank_info.account, //银行账户
                },
              ];
            }
            this.orderInfo.orderNo = res.data.result.order_no; //投保单号：
            this.orderInfo.insuranceNo = res.data.result.proposal_no; //投保单号：
            this.orderInfo.sum = res.data.result.after_prem; //应付金额：
            this.orderInfo.issueCompany =
              res.data.result.extend.insurance_com_list; //本保险的出单公司：
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    computedIssueCompany() {
      return (arr) => {
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].party_role == 2) {
            return arr[i].name;
          }
        }
      };
    },
  },
};
</script>

<style scoped>
.box-card {
  width: 1200px;
  margin: 0 auto 20px;
  text-align: left;
}
.head {
  display: flex;
}
.right dd {
  margin-left: 0;
  margin-right: 20px;
  line-height: 20px;
}
.right dt {
  margin-left: 20px;
  line-height: 20px;
}
.p1 {
  margin-left: 60px;
}
.p1 dd {
  margin-left: 0;
  margin-right: 30px;
}
</style>