<template>
  <div>
    <el-card class="box-card" shadow="hover">
      <h3>投保人信息</h3>
      <el-divider></el-divider>
      <dl class="item1">
        <dt>投保企业名:</dt>
        <dd>{{ applicantInfo.com_name }}</dd>
        <dt>行业领域:</dt>
        <dd>{{ applicantInfo.industry_value }}</dd>
        <dt>统一社会信用代码:</dt>
        <dd>{{ applicantInfo.idcard }}</dd>
        <dt>企业地址:</dt>
        <dd>{{ applicantInfo.p_c_a_ch + "-" + applicantInfo.address }}</dd>
        <dt>单位性质:</dt>
        <dd>{{ applicantInfo.unit_nature_value }}</dd>
        <dt>客户类型:</dt>
        <dd>团体</dd>
        <dt>联系人姓名:</dt>
        <dd>{{ applicantInfo.link_name }}</dd>
        <dt>联系方式:</dt>
        <dd>{{ applicantInfo.link_phone }}</dd>
        <dt>联系人地址:</dt>
        <dd>
          {{ applicantInfo.link_p_c_a_ch + "-" + applicantInfo.link_address }}
        </dd>
      </dl>
      <el-form ref="formData" :model="formData" label-width="218px">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: -18px;
          "
        >
          <h3>被保人信息</h3>
          <el-checkbox v-model="checked">同投保人</el-checkbox>
        </div>
        <el-divider></el-divider>
        <div class="item">
          <el-form-item
            label="被保企业名称: "
            prop="name"
            :rules="[
              {
                required: true,
                message: '请输入被保企业名称',
                trigger: 'change',
              },
            ]"
          >
            <el-input v-model="formData.name" :disabled="isLock"></el-input>
          </el-form-item>

          <el-form-item
            prop="address1"
            label="被保企业地址: "
            :rules="[
              {
                validator: validateAddress,
                trigger: 'change',
              },
              {
                required: true,
                message: '请输入被保企业地址',
                trigger: 'change',
              },
            ]"
          >
            <el-cascader
              ref="cascader1"
              size="large"
              :options="addressOptions"
              v-model="formData.address1"
              @change="handleChange1"
              style="margin-bottom: 10px"
              :disabled="isLock"
            >
            </el-cascader>
            <el-input
              v-model="formData.address2"
              placeholder="详细地址"
              :disabled="isLock"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="主要经营范围: "
            prop="range"
            :rules="[
              {
                required: true,
                message: '请输入主要经营范围',
                trigger: 'change',
              },
            ]"
          >
            <el-input
              type="textarea"
              v-model="formData.range"
              :disabled="isLock"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="社会信用代码: "
            prop="code"
            :rules="[
              {
                validator: validateSocialCode,
                trigger: 'change',
              },
              {
                required: true,
                message: '请输入社会信用代码',
                trigger: 'change',
              },
            ]"
          >
            <el-input v-model="formData.code" :disabled="isLock"></el-input>
          </el-form-item>

          <!-- 单位性质   -->
          <el-form-item
            label="经营性质: "
            style="text-align: left"
            prop="business_nature"
            :rules="[
              {
                required: true,
                message: '请选择',
                trigger: 'change',
              },
            ]"
          >
            <el-cascader
              ref="business_nature_des"
              placeholder="请选择"
              v-model="formData.business_nature"
              :options="businessTypeOptions"
              @change="handleChangeBusiness_nature_des"
              expandTrigger="hover"
              :props="{ value: 'value_code', label: 'element_values' }"
              :disabled="isLock"
            ></el-cascader>
          </el-form-item>

          <!-- 被保企业联系人 联系人姓名 -->
          <el-form-item
            label="联系人姓名: "
            prop="link_name"
            :rules="[
              {
                required: true,
                message: '请输入联系人姓名',
                trigger: 'change',
              },
            ]"
          >
            <el-input
              v-model="formData.link_name"
              :disabled="isLock"
            ></el-input>
          </el-form-item>

          <!-- 被保企业联系方式 -->
          <el-form-item
            label="联系人电话: "
            prop="mobile"
            :rules="[
              {
                required: true,
                message: '请输入联系人电话',
                trigger: 'change',
              },
              {
                min: 11,
                max: 20,
                message: '请输入正确的号码位数',
                trigger: 'blur',
              },
            ]"
          >
            <el-input v-model="formData.mobile" :disabled="isLock"></el-input>
          </el-form-item>
          <!-- 被保企业联系地址 -->
          <el-form-item
            label="联系人地址: "
            style="text-align: left"
            prop="link_address"
            :rules="[
              {
                validator: validatelink_address,
                trigger: 'change',
              },
            ]"
          >
            <el-cascader
              ref="link_p_c_a_name"
              size="large"
              :options="addressOptions"
              v-model="formData.link_p_c_a_code"
              @change="handleChangeLink_p_c_a_name"
              style="margin-bottom: 10px"
              :disabled="isLock"
            >
            </el-cascader>
            <el-input
              v-model="formData.link_address"
              placeholder="详细地址"
              :disabled="isLock"
            ></el-input>
          </el-form-item>

          <!-- 上传营业执照 -->
          <el-form-item
            prop="Upload"
            :rules="[
              {
                validator: validateUpload,
                trigger: 'change',
              },
            ]"
          >
            <span style="margin-left: -97px"
              ><span slot="label" style="color: red">*</span>上传营业执照:
            </span>
            <el-upload
              :headers="computedHeaders"
              name="bl"
              class="upload-demo"
              :action="computedBaseURl + '/api/v1/batchUpload'"
              :on-success="uploadSuccess"
              :on-error="uploadError"
              :on-change="handleChange"
              :before-upload="beforeUpload"
              :file-list="fileList"
              :on-remove="handleRemove"
              :disabled="checked"
            >
              <img v-if="ImageUrl" :src="ImageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <el-button size="small" type="primary" :disabled="isLock"
                >点击上传</el-button
              >
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过2m
              </div>
            </el-upload>
          </el-form-item>
        </div>
        <h3>产品方案</h3>
        <el-divider></el-divider>

        <div class="item">
          <!-- 从业人数 -->
          <el-form-item
            label="从业人数: "
            prop="peopleNum"
            :rules="[
              {
                validator: validateNum,
                trigger: 'change',
              },
            ]"
          >
            <el-input-number
              :min="0"
              :max="9000000"
              :step-strictly="true"
              v-model="formData.peopleNum"
            ></el-input-number>
          </el-form-item>

          <!-- "19YZ336205":"月数（生产、批发、零售经营者）", -->
          <el-form-item
            label="月数（生产、批发、零售经营者）: "
            v-if="
              factor19YZ336205Options.coefficient &&
                factor19YZ336205Options.coefficient.length > 0
            "
            :rules="[
              {
                required: true,
                message: '请输入月数（生产、批发、零售经营者）',
                trigger: 'change',
              },
            ]"
            prop="factor19YZ336205"
          >
            <el-select
              v-model="formData.factor19YZ336205"
              placeholder="请选择"
              class="selectWidth"
            >
              <el-option
                v-for="item in factor19YZ336205Options.coefficient"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 保险期间      prop="start_date"  -->
          <el-form-item
            prop="start_date"
            :rules="[
              {
                required: true,
                validator: validatestart_date,
                trigger: 'change',
              },
            ]"
          >
            <span style="margin-left: -97px">
              <span slot="label" style="color: red">*</span>保险期间:
            </span>
            <!--   -->
            <el-date-picker
              :picker-options="pickerOptions"
              v-model="formData.start_date"
              type="date"
              placeholder="选择日期"
              @change="changeDataPicker"
            >
            </el-date-picker>
            <span style="margin: 0 8px">
              00时起至 {{ formData.end_date }} 时止
            </span>
          </el-form-item>

          <!-- 企业风险系数 -->
          <el-form-item label="企业风险系数">
            <el-radio
              v-model="companyRiskFactorList.industry"
              :label="companyRiskFactorList.industry"
              >{{ companyRiskFactorList.industry_value }}</el-radio
            >
          </el-form-item>

          <p
            style="
              color: #096;
              text-align: center;
              font-size: 16px;
              margin: 30px 0;
              font-weight: bold;
            "
          >
            基本险（从业人员及第三者人身伤害赔偿）
          </p>
          <!-- 每次及累计责任限额 -->
          <el-form-item
            label="每次及累计责任限额: "
            prop="dutyLimit"
            :rules="[
              {
                required: true,
                message: '请输入每次及累计责任限额',
                trigger: 'change',
              },
            ]"
          >
            <el-select
              v-model="formData.dutyLimit"
              @change="changeDutyLimit"
              placeholder="请选择"
              class="selectWidth"
            >
              <el-option
                v-for="item in dutyLimitOptions"
                :key="item.code"
                :label="computedName(item.name)"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 每人责任限额调整因子（广西） -->
          <el-form-item
            v-if="
              personDutyLimitOptions.coefficient &&
                personDutyLimitOptions.coefficient.length > 0
            "
            label="每人责任限额调整因子（广西）: "
            prop="personDutyLimit"
            :rules="[
              {
                required: true,
                message: '请输入每人责任限额调整因子（广西）',
                trigger: 'change',
              },
            ]"
          >
            <el-select
              v-model="formData.personDutyLimit"
              @change="changePersonDutyLimit"
              placeholder="请选择"
              class="selectWidth"
            >
              <el-option
                v-for="item in personDutyLimitOptions.coefficient"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>

            <el-popover placement="right" width="400" trigger="hover">
              <div>
                <p>每次事故及累计救援费用责任限额：累计责任限额的20%；</p>
                <p>每次事故及累计法律费用责任限额：累计责任限额的20%;</p>
                <p>每次事故财产损失责任限额：累计责任限额的30%;</p>
                <p>每次事故每人医疗费用责任限额：每次事故每人责任限额的30%;</p>
                <p>
                  每次事故每位第三者随身携带财产损失责任限额：每次事故每人责任限额的5%;
                </p>
                <p>
                  注：如被保险人经营范围包含地下开采，则每次事故及累计救援费用责任限额为累计责任限额的40%
                </p>
              </div>
              <el-button
                type="text"
                slot="reference"
                class="el-icon-question"
                style="margin-left: 20px"
              ></el-button>
            </el-popover>
          </el-form-item>

          <p
            style="
              color: #096;
              text-align: center;
              font-size: 16px;
              margin: 30px 0;
              font-weight: bold;
            "
          >
            附加险（可选）
          </p>
          <!--1 附加补充雇主责任险 -->
          <div v-if="this.risk_prog_list.length > 0">
            <el-checkbox v-model="additionalRisk1">
              附加补充雇主责任险
            </el-checkbox>

            <el-form-item
              label="每次及累计责任限额"
              v-if="additionalRisk1"
              :rules="[
                {
                  required: true,
                  message: '请选择每次及累计责任限额',
                  trigger: 'change',
                },
              ]"
              prop="additionalRisk1Limit"
            >
              <el-select
                v-model="formData.additionalRisk1Limit"
                placeholder="请选择"
                class="selectWidth"
              >
                <!-- additionalRisk1FactorOptions -->
                <el-option
                  v-for="(item, i) in additionalRisk1LimitOptions"
                  :key="i"
                  :label="computedName(item.name)"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="每人责任限额调整因子（补充雇主）"
              v-if="additionalRisk1"
              :rules="[
                {
                  required: true,
                  message: '请选择每人责任限额调整因子（补充雇主）',
                  trigger: 'change',
                },
              ]"
              prop="additionalRisk1Limit"
            >
              <el-select
                v-model="formData.additionalRisk1Factor"
                placeholder="请选择"
                class="selectWidth"
              >
                <el-option
                  v-for="(item, i) in additionalRisk1FactorOptions.coefficient"
                  :key="i"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <!--2 附加燃放责任保险 -->
          <div v-if="this.risk_prog_list.length > 0">
            <el-checkbox v-model="additionalRisk2">
              附加燃放责任保险
            </el-checkbox>
            <el-form-item
              label="每次及累计责任限额"
              v-if="additionalRisk2"
              :rules="[
                {
                  required: true,
                  message: '请选择每次及累计责任限额',
                  trigger: 'change',
                },
              ]"
              prop="additionalRisk2Limit"
            >
              <el-select
                v-model="formData.additionalRisk2Limit"
                placeholder="请选择"
                class="selectWidth"
              >
                <el-option
                  v-for="(item, i) in additionalRisk2LimitOptions"
                  :key="i"
                  :label="computedName(item.name)"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="每人责任限额调整因子（燃放责任保险）"
              v-if="additionalRisk2"
              :rules="[
                {
                  required: true,
                  message: '请选择每次及累计责任限额',
                  trigger: 'change',
                },
              ]"
              prop="additionalRisk2Factor"
            >
              <el-select
                v-model="formData.additionalRisk2Factor"
                placeholder="请选择"
                class="selectWidth"
              >
                <el-option
                  v-for="(item, i) in additionalRisk2FactorOptions.coefficient"
                  :key="i"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <!--3 附加补充公众责任险 -->
          <div v-if="this.risk_prog_list.length > 0">
            <el-checkbox v-model="additionalRisk3">
              附加补充公众责任险
            </el-checkbox>
            <el-form-item
              label="每次及累计责任限额"
              v-if="additionalRisk3"
              :rules="[
                {
                  required: true,
                  message: '请选择每次及累计责任限额',
                  trigger: 'change',
                },
              ]"
              prop="additionalRisk3Limit"
            >
              <el-select
                v-model="formData.additionalRisk3Limit"
                placeholder="请选择"
                class="selectWidth"
              >
                <el-option
                  v-for="(item, i) in additionalRisk3LimitOptions"
                  :key="i"
                  :label="computedName(item.name)"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="每人责任限额调整因子（补充公众）"
              v-if="additionalRisk3"
              :rules="[
                {
                  required: true,
                  message: '请选择每次及累计责任限额',
                  trigger: 'change',
                },
              ]"
              prop="additionalRisk3Factor"
            >
              <el-select
                v-model="formData.additionalRisk3Factor"
                placeholder="请选择"
                class="selectWidth"
              >
                <el-option
                  v-for="(item, i) in additionalRisk3FactorOptions.coefficient"
                  :key="i"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <p
            style="
              color: #096;
              text-align: center;
              font-size: 16px;
              margin: 30px 0;
              font-weight: bold;
            "
          >
            风险管理调整系数
          </p>

          <!-- 以往赔偿记录调整因子 -->
          <el-form-item
            v-if="
              reparationFactorOptions.coefficient &&
                reparationFactorOptions.coefficient.length > 0
            "
            label="以往赔偿记录调整因子: "
            :rules="[
              {
                required: true,
                message: '请输入以往赔偿记录调整因子',
                trigger: 'change',
              },
            ]"
            prop="reparationFactor"
          >
            <el-select
              v-model="formData.reparationFactor"
              placeholder="请选择"
              class="selectWidth"
            >
              <el-option
                v-for="item in reparationFactorOptions.coefficient"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>

            <el-popover placement="right" width="400" trigger="hover">
              <div>
                <p>①首年投保本产品企业适用。</p>
                <p>
                  ②近三年指投保当年往前追溯三个自然年度（含投保当年），即投保当年、去年、前年。
                </p>
                <p>
                  ③特别重大事故，是指造成30人以上死亡，或者100人以上重伤（包括急性工业中毒，下同）；重大事故，是指造成10人以上30人以下死亡，或者50人以上100人以下重伤；较大事故，是指造成3人以上10人以下死亡，或者10人以上50人以下重伤；一般事故，是指造成3人以下死亡，或者10人以下重伤。在本保险合同中直接经济损失不作为事故分类的评判依据。
                </p>
              </div>
              <el-button
                type="text"
                slot="reference"
                class="el-icon-question"
                style="margin-left: 20px"
              ></el-button>
            </el-popover>
          </el-form-item>

          <!-- 重大危险源调整因子 -->
          <el-form-item
            v-if="
              dangerSourceFactorOptions.coefficient &&
                dangerSourceFactorOptions.coefficient.length > 0
            "
            label="重大危险源调整因子: "
            :rules="[
              {
                required: true,
                message: '请输入重大危险源调整因子',
                trigger: 'change',
              },
            ]"
            prop="dangerSourceFactor"
          >
            <el-select
              v-model="formData.dangerSourceFactor"
              placeholder="请选择"
              class="selectWidth"
            >
              <el-option
                v-for="item in dangerSourceFactorOptions.coefficient"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
            <el-popover placement="right" width="400" trigger="hover">
              <div>
                <p>
                  ①重大危险源是指长期地或者临时地生产、搬运、使用或储存危险物品，且危险物品的数量等于或超过国家规定的临界量的单元（包括场所和设施）；
                </p>
                <p>
                  ②若投保企业有不同级别的重大危险源，则该企业浮动比例按照其级别最高的重大危险源对应的浮动比例确定；
                </p>
                <p>③危险源个数指投保企业所有级别危险源个数的总和；</p>
                <p>
                  ④重大危险源调整因子=（1+重大危险源浮动比例）*（1+危险源个数浮动比例）。
                </p>
              </div>
              <el-button
                type="text"
                slot="reference"
                class="el-icon-question"
                style="margin-left: 20px"
              ></el-button>
            </el-popover>
          </el-form-item>

          <!-- 安全管理水平调整因子(煤矿) -->
          <el-form-item
            label="安全管理水平调整因子(煤矿): "
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: 'change',
              },
            ]"
            prop="manageLevelFactor"
            v-if="
              manageLevelFactorOptions.coefficient &&
                manageLevelFactorOptions.coefficient.length > 0
            "
          >
            <el-select
              v-model="formData.manageLevelFactor"
              placeholder="请选择安全管理水平调整因子"
              class="selectWidth"
            >
              <el-option
                v-for="item in manageLevelFactorOptions.coefficient"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
            <el-popover placement="right" width="400" trigger="hover">
              <div>
                <p>本调整因子仅适用于上年度无死亡、重伤事故的企业。</p>
              </div>
              <el-button
                type="text"
                slot="reference"
                class="el-icon-question"
                style="margin-left: 20px"
              ></el-button>
            </el-popover>
          </el-form-item>

          <!-- 安全文化建设示范企业因子 -->
          <el-form-item
            v-if="
              exampleFactorOptions.coefficient &&
                exampleFactorOptions.coefficient.length > 0
            "
            label="安全文化建设示范企业因子: "
            :rules="[
              {
                required: true,
                message: '请输入安全文化建设示范企业因子',
                trigger: 'change',
              },
            ]"
            prop="exampleFactor"
          >
            <el-select v-model="formData.exampleFactor" placeholder="请选择">
              <el-option
                v-for="item in exampleFactorOptions.coefficient"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
            <el-popover placement="right" width="400" trigger="hover">
              <div>
                <p>本调整因子仅适用于上年度无死亡、重伤事故的企业。</p>
              </div>
              <el-button
                type="text"
                slot="reference"
                class="el-icon-question"
                style="margin-left: 20px"
              ></el-button>
            </el-popover>
          </el-form-item>

          <!-- "19YZ261411":"企业安全生产标准化因子", factor19YZ261411-->
          <el-form-item
            label="企业安全生产标准化因子: "
            v-if="
              factor19YZ261411Options.coefficient &&
                factor19YZ261411Options.coefficient.length > 0
            "
            :rules="[
              {
                required: true,
                message: '请输入企业安全生产标准化因子',
                trigger: 'change',
              },
            ]"
            prop="factor19YZ261411"
          >
            <el-select
              v-model="formData.factor19YZ261411"
              placeholder="请选择"
              class="selectWidth"
            >
              <el-option
                v-for="item in factor19YZ261411Options.coefficient"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
            <el-popover placement="right" width="400" trigger="hover">
              <div>
                <p>本调整因子仅适用于上年度无死亡、重伤事故的企业。</p>
              </div>
              <el-button
                type="text"
                slot="reference"
                class="el-icon-question"
                style="margin-left: 20px"
              ></el-button>
            </el-popover>
          </el-form-item>

          <!-- "19YZ573684":"季节性零售月数", -->
          <!--       <el-form-item
            label="季节性零售月数: "
            v-if="
              factor19YZ573684Options.coefficient &&
              factor19YZ573684Options.coefficient.length > 0
            "
            :rules="[
              {
                required: true,
                message: '请输入季节性零售月数',
                trigger: 'change',
              },
            ]"
            prop="factor19YZ573684"
          >
            <el-select
              v-model="formData.factor19YZ573684"
              placeholder="请选择"
              class="selectWidth"
            >
              <el-option
                v-for="item in factor19YZ573684Options.coefficient"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item> -->

          <!-- 因子 是否参保工伤保险 -->
          <el-form-item
            prop="is_social_security"
            :rules="[
              {
                required: true,
                message: '请选择是否参保工伤保险',
                trigger: 'change',
              },
            ]"
          >
            <span slot="label">社会工伤保险： </span>
            <el-select
              v-model="formData.is_social_security"
              placeholder="请选择是否参保工伤保险"
              class="selectWidth"
            >
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="2"></el-option>
            </el-select>
          </el-form-item>
          <!--是否参保工伤保险 提交图片     {
                    "file_type":"gspz",
                    "name":"工伤保险缴费凭证",
                    "value":"12345678998765432a",
                    "file_code":"1644886144b967b1254c62a584bc9739e100637641"
                } -->
          <el-form-item prop="Upload1">
            <span style="margin-left: -137px">
              <!--     <span slot="label" style="color: red">*</span> -->工伤保险缴费凭证:
            </span>
            <el-upload
              :headers="computedHeaders"
              name="gspz"
              class="upload-demo"
              :action="computedBaseURl + '/api/v1/batchUpload'"
              :on-success="uploadSuccess1"
              :on-error="uploadError"
              :on-change="handleChange_1"
              :before-upload="beforeUpload"
              :file-list="fileList1"
              :on-remove="handleRemove1"
            >
              <img v-if="ImageUrl1" :src="ImageUrl1" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过2m
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item label="选择保单: ">
            <el-radio v-model="formData.mail_type" label="1">电子保单</el-radio>
            <el-radio v-model="formData.mail_type" label="2">纸质保单</el-radio>
          </el-form-item>
          <!-- 

      :rules="[{ validator: validateMail, trigger: 'blur' }]"
 -->
          <template v-if="formData.mail_type == 1">
            <el-form-item
              prop="docAddress"
              :rules="[
                {
                  validator: validatedocAddress,
                  trigger: 'blur',
                },
              ]"
            >
              <span slot="label">
                <span style="color: red">*</span> 邮箱:
              </span>
              <el-input v-model="formData.docAddress"></el-input>
            </el-form-item>
          </template>

          <template v-else>
            <el-form-item
              prop="docAddress1"
              :rules="[
                {
                  validator: validatedocAddress1,
                  trigger: 'change',
                },
              ]"
            >
              <span slot="label">
                <span style="color: red">* </span>邮寄地址：</span
              >
              <el-cascader
                ref="cascader2"
                size="large"
                :options="addressOptions"
                v-model="formData.docAddress1"
                @change="handleChange2"
                style="margin-bottom: 20px"
              >
              </el-cascader>
              <el-input v-model="formData.docAddress2"></el-input>
            </el-form-item>
          </template>

          <!-- renew insurance -->
          <div v-if="isRenewInsurance">
            <h3 style="color: green; border: 0">续保</h3>
            <el-table
              ref="multipleTable"
              :data="renewTableData"
              style="width: 100%"
            >
              <el-table-column width="35">
                <template slot-scope="scope">
                  <el-radio
                    :label="scope.row.order_no"
                    v-model="radioOrderNo"
                    @change="changeRow(scope.row)"
                  >
                  </el-radio>
                </template>
              </el-table-column>

              <el-table-column prop="policy_no" label="保单号" width="230">
              </el-table-column>
              <el-table-column prop="insurer_name" label="被保人名称">
              </el-table-column>
              <el-table-column prop="start_time" label="开始时间">
              </el-table-column>
              <el-table-column prop="end_time" label="结束时间">
              </el-table-column>
              <el-table-column
                prop="after_prem"
                label="价格"
                width="180"
              ></el-table-column>
            </el-table>
          </div>

          <!-- calculate -->
          <div v-if="isShowTable">
            <h3 style="color: green; border: 0">计算表达式</h3>
            <div style="display: flex">
              <div style="width: 200px; margin-left: 12px; line-height: 30px">
                保险费 =
              </div>
              <div style="margin-left: 0; line-height: 30px">
                {{ premium_memo }}
              </div>
            </div>
            <el-table
              :data="tableData"
              style="width: 100%; margin-bottom: 20px"
            >
              <el-table-column prop="risk_name" label="险种"> </el-table-column>
              <el-table-column prop="risk_premiuml" label="保费金额（元）">
                <span slot-scope="scope">
                  {{ computedCost(scope.row) }}
                </span>
              </el-table-column>
              <el-table-column label="优惠比例">
                <span slot-scope="scope">
                  {{ computedRate(scope.row) }}
                </span>
              </el-table-column>
              <el-table-column prop="risk_premiuml" label="保费合计（元）">
                <span slot-scope="scope">
                  {{ computedTotal(scope.row) }}
                </span>
              </el-table-column>
            </el-table>

            <el-form-item label="是否投保单审核: ">
              <el-radio v-model="formData.policy_audit" label="1"
                >审核</el-radio
              >
              <el-radio v-model="formData.policy_audit" label="2"
                >不审核</el-radio
              >
            </el-form-item>
          </div>

          <el-form-item v-if="!isShowSubmit">
            <div
              v-if="errorStop.flag"
              style="color: #f56c6c; margin-left: 20px"
            >
              {{ errorStop.msg }}
            </div>
            <el-button @click="countFactor()" :disabled="errorStop.flag"
              >计算保费</el-button
            >
          </el-form-item>

          <el-form-item v-else>
            <el-button type="primary" @click="onSubmit1()">下一步</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-card>
    <!-- dialog 确认投保信息 -->
    <el-dialog
      class="comfirmDialog"
      title="确认投保信息"
      :visible.sync="isShow"
      @close="closeDialog"
      :fullscreen="true"
    >
      <div class="content1">
        <span class="head">投保人信息</span>
        <el-divider></el-divider>
        <div class="item">
          <div class="width50">
            <span class="title">投保企业名：</span>
            <span class="textcolor">{{ insureInfo.holder.companyName }}</span>
          </div>

          <div class="width50">
            <span class="title">行业领域：</span>
            <span class="textcolor">{{ insureInfo.holder.field }}</span>
          </div>

          <div class="width50">
            <span class="title">统一社会信用代码：</span>
            <span class="textcolor">{{ insureInfo.holder.code }}</span>
          </div>

          <div class="width50">
            <span class="title">企业地址：</span>
            <span class="textcolor">{{
              insureInfo.holder.companyAddress
            }}</span>
          </div>

          <div class="width50">
            <span class="title">客户类型：</span>
            <span class="textcolor">团体</span>
          </div>

          <div class="width50">
            <span class="title">单位性质：</span>
            <span class="textcolor">{{
              insureInfo.holder.companyCharactor
            }}</span>
          </div>

          <div class="width50">
            <span class="title">联系人姓名：</span>
            <span class="textcolor">{{ insureInfo.holder.name }}</span>
          </div>

          <div class="width50">
            <span class="title">联系方式：</span>
            <span class="textcolor">{{ insureInfo.holder.phone }}</span>
          </div>

          <div class="width50">
            <span class="title">联系人地址：</span>
            <span class="textcolor">{{ insureInfo.holder.address }}</span>
          </div>

          <div class="width50">
            <span class="title">社会工伤保险：</span>
            <span class="textcolor">{{
              insureInfo.holder.isSocial == 1 ? "是" : "否"
            }}</span>
          </div>
        </div>

        <span class="head">被保人信息</span>
        <el-divider></el-divider>
        <div class="item">
          <div class="width50">
            <span class="title">被保企业名称：</span>
            <span class="textcolor">{{ insureInfo.insurer.companyName }}</span>
          </div>

          <!--   <div class="width50">
            <span class="title">被保人联系方式：</span>
            <span class="textcolor">{{ insureInfo.insurer.phone }}</span>
          </div> -->

          <div class="width50">
            <span class="title">被保企业地址：</span>
            <span class="textcolor" v-if="insureInfo.insurer.address">{{
              insureInfo.insurer.address.replace(/\,/g, "-")
            }}</span>
          </div>

          <div class="width50">
            <span class="title">社会信用代码：</span>
            <span class="textcolor">{{ insureInfo.insurer.code }}</span>
          </div>

          <div class="width50">
            <span class="title">单位性质：</span>
            <span class="textcolor">{{
              this.formData.business_nature_des
            }}</span>
          </div>

          <div class="width50">
            <span class="title">被保企业联系人：</span>
            <span class="textcolor">{{ formData.link_name }}</span>
          </div>

          <div class="width50">
            <span class="title">被保企业联系方式：</span>
            <span class="textcolor">{{ formData.mobile }}</span>
          </div>

          <div class="width50">
            <span class="title">被保企业联系地址：</span>
            <span class="textcolor" v-if="formData.link_p_c_a_name">{{
              formData.link_p_c_a_name.replace(/\,/g, "-") +
                " " +
                formData.link_address
            }}</span>
          </div>

          <div style="display: flex">
            <span class="title" style="width: 120px">主要经营范围：</span>
            <span class="textcolor">{{ insureInfo.insurer.field }}</span>
          </div>
        </div>

        <span class="head">产品方案</span>
        <el-divider></el-divider>
        <div class="item">
          <div class="width50">
            <span class="title">从业人数：</span>
            <span class="textcolor">{{ insureInfo.case.staffNumber }}</span>
          </div>
          <div class="width50" v-if="insureInfo.case.monthNumber">
            <span class="title">月数（生产、批发、零售经营者）：</span>
            <span class="textcolor">{{ insureInfo.case.monthNumber }}</span>
          </div>
          <div class="width50">
            <span class="title">保险时间：</span>
            <span class="textcolor">{{ insureInfo.case.duringTime }}</span>
          </div>
          <div
            class="width50"
            v-if="insureInfo.case.additonInsure.additon3.limit"
          >
            <span class="title">企业风险系数：</span>
            <span class="textcolor">{{
              insureInfo.case.companyRiskFactor
            }}</span>
          </div>
        </div>

        <h4>基本险（从业人员及第三者人身伤害赔偿）：</h4>
        <div class="item">
          <div class="width50">
            <span class="title">每次事故每人责任限额：</span>
            <span class="textcolor">{{
              insureInfo.case.basicInsure.limit
            }}</span>
          </div>
          <div class="width50">
            <span class="title">每人责任限额调整因子（广西）：</span>
            <span class="textcolor">{{
              insureInfo.case.basicInsure.factor
            }}</span>
          </div>
        </div>

        <h4>附加险：</h4>
        <h4 v-if="insureInfo.case.additonInsure.additon1.limit">
          附加补充雇主责任险：
        </h4>
        <div class="item1" v-if="insureInfo.case.additonInsure.additon1.limit">
          <div class="width50">
            <span class="title">每次及累计责任限额：</span>
            <span class="textcolor">{{
              insureInfo.case.additonInsure.additon1.limit
            }}</span>
          </div>
          <div class="width50">
            <span class="title">每人责任限额调整因子（补充雇主）：</span>
            <span class="textcolor">{{
              insureInfo.case.additonInsure.additon1.factor
            }}</span>
          </div>
        </div>

        <h4 v-if="insureInfo.case.additonInsure.additon2.limit">
          附加燃放责任保险：
        </h4>
        <div class="item1" v-if="insureInfo.case.additonInsure.additon2.limit">
          <div class="width50">
            <span class="title">每次及累计责任限额：</span>
            <span class="textcolor">{{
              insureInfo.case.additonInsure.additon2.limit
            }}</span>
          </div>
          <div class="width50">
            <span class="title">每人责任限额调整因子（燃放责任保险）：</span>
            <span class="textcolor">{{
              insureInfo.case.additonInsure.additon2.factor
            }}</span>
          </div>
        </div>

        <h4 v-if="insureInfo.case.additonInsure.additon3.limit">
          附加补充公众责任险：
        </h4>
        <div class="item1" v-if="insureInfo.case.additonInsure.additon3.limit">
          <div class="width50">
            <span class="title">每次及累计责任限额：</span>
            <span class="textcolor">{{
              insureInfo.case.additonInsure.additon3.limit
            }}</span>
          </div>
          <div class="width50">
            <span class="title">每人责任限额调整因子（补充公众）：</span>
            <span class="textcolor">{{
              insureInfo.case.additonInsure.additon3.factor
            }}</span>
          </div>
        </div>

        <!-- 因子 -->
        <h4>风险管理调整系数：</h4>
        <div class="item2">
          <div v-for="(item, i) in factor" :key="i">
            <div v-if="item.factor">
              {{ item.name }}
              <span
                class="textcolor"
                v-for="(option, j) in item.options"
                :key="j"
              >
                <span v-if="option.code == item.factor">
                  {{ option.name }}</span
                >
              </span>
            </div>
          </div>
        </div>

        <div class="item">
          <span
            >选择保单：
            <span class="textcolor">{{
              insureInfo.case.selectInsure == 1 ? "电子保单" : "纸质保单"
            }}</span>
          </span>
        </div>

        <div class="item" v-if="insureInfo.case.selectInsure == 1">
          <span
            >邮箱：
            <span class="textcolor">{{ insureInfo.case.mailAdress }}</span>
          </span>
        </div>

        <div class="item" v-if="insureInfo.case.selectInsure == 2">
          <span
            >邮寄地址：
            <span class="textcolor">{{
              computedAddress(insureInfo.case.address)
            }}</span>
          </span>
        </div>

        <span class="head">保单试算</span>
        <el-divider></el-divider>
        <div style="display: flex; margin: 0 0 10px 0">
          <div
            style="
              width: 200px;
              margin-left: 12px;
              line-height: 30px;
              text-align: left;
              flex: none;
            "
          >
            计算公式：
          </div>
          <div style="margin-left: 0; line-height: 30px; text-align: left">
            {{ premium_formula }}
          </div>
        </div>

        <div style="display: flex; margin: 0 0 10px 0">
          <div
            style="
              width: 200px;
              margin-left: 12px;
              line-height: 30px;
              text-align: left;
            "
          >
            总保费：
          </div>
          <div style="margin-left: 0; line-height: 30px; text-align: left">
            {{ total_premium + " 元" }}
          </div>
        </div>

        <div style="display: flex; margin: 0 0 10px 0">
          <div
            style="
              width: 200px;
              margin-left: 12px;
              line-height: 30px;
              text-align: left;
            "
          >
            是否投保单审核：
          </div>
          <div style="margin-left: 0; line-height: 30px; text-align: left">
            {{ insureInfo.case.isCheck }}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="onSubmit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { regionData } from "element-china-area-data";
export default {
  data() {
    return {
      errorStop: {
        flag: false,
        msg: "",
      },
      security: null,
      businessTypeOptions: [], //经营性质
      radioOrderNo: "", //续保单号
      selectedRadio: {},
      premium_formula: "",
      risk_premiuml: "", //总保费
      factor: [],
      isShow: false,
      insureInfo: {
        //dialog 展示
        holder: {
          //投保人信息
          companyName: "", //投保企业名
          field: "", //行业领域
          code: "", //统一社会信用代码
          companyAddress: "", //企业地址
          type: "团体", // 客户类型 团体
          companyCharactor: "", //单位性质
          name: "", //  联系人姓名
          phone: "", //联系方式
          address: "", //联系人地址
          isSocial: 1, //社会工伤保险
        },
        insurer: {
          //被保人信息
          companyName: "", //被保企业名称:
          phone: "", //被保人联系方式:
          address: "", //*被保企业地址:
          field: "", //主要经营范围:
          code: "", //社会信用代码:
        },
        case: {
          staffNumber: 0, //从业人数:
          monthNumber: 0, //月数（生产、批发、零售经营者）:
          duringTime: " ", // 保险期间:
          companyRiskFactor: "", //企业风险系数 烟花爆竹,生产,黑火药、引火线生产企业 写死的 登录里拿
          basicInsure: {
            //基本险（从业人员及第三者人身伤害赔偿）
            limit: "", //每次及累计责任限额:
            factor: "", //每人责任限额调整因子（广西）:
          },
          additonInsure: {
            //附加险（可选）//判断显示
            additon1: {
              //附加补充雇主责任险
              limit: "", //每次及累计责任限额
              factor: "", //每人责任限额调整因子（补充雇主）
            },
            additon2: {
              //附加燃放责任保险
              //每次及累计责任限额
              //每人责任限额调整因子（燃放责任保险）
            },
            additon3: {
              //附加补充公众责任险
              //每次及累计责任限额
              //每人责任限额调整因子（补充公众）
            },
          },
          riskFactor: [
            //循环实现
            {
              //风险管理调整系数
            },
            {
              //以往赔偿记录调整因子:
            },
            {
              //重大危险源调整因子:
            },
            {
              //安全文化建设示范企业因子:
            },
            {
              //企业安全生产标准化因子:
            },
          ],
          //选择保单:电子保单纸质保单
          selectInsure: "",
          mailAdress: "", //邮箱
          address: "", //纸单邮寄地址
          isCheck: "", //是否投保单审核

          formula: "", //保费计算表达式
          risk_premiums: [
            //保费计算 list
            /*     {
              risk_code: "XZ9999",
              risk_name: "广西壮族自治区安全生产责任保险",
              risk_flag: "M",
              risk_formulas:
                "26120*1.5*(1+0.3)*(1+(-0.1)+(-0.03))*(1+0.03)*(1+0.1)*0.3",
              risk_memo:
                "广西壮族自治区安全生产责任保险保费×企业风险系数×(1+每人责任限额调整因子（广西）)×(1+安全文化建设示范企业因子+企业安全生产标准化因子)×(1+重大危险源调整因子)×(1+以往赔偿记录调整因子)×月数（生产、批发、零售经营者）",
              risk_premiuml: "15061.8459",
            }, */
          ],
          total_premium: "", //保费计算 total
        },
      },
      steps: 0,
      total_premium: "",
      risk_prog_list: [],
      additionalRisk1FactorOptions: [],
      additionalRisk2FactorOptions: [],
      additionalRisk3FactorOptions: [],
      additionalRisk1LimitOptions: [], //主险附加险
      additionalRisk2LimitOptions: [],
      additionalRisk3LimitOptions: [],
      additionalRisk1: false,
      additionalRisk2: false,
      additionalRisk3: false,
      // checked:'',
      risk_prog_list: [], //附加险
      pickerOptions: {
        disabledDate(d) {
          return d.getTime() < new Date().getTime();
        },
      },

      validateMail: (rule, value, callback) => {
        if (!this.formData.docAddress) {
          return callback(new Error("邮箱不能为空"));
        } else if (
          !/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
            this.formData.docAddress
          ) ||
          this.formData.docAddress.length > 200
        ) {
          return callback(new Error("需要正确的邮箱格式"));
        } else {
          callback();
        }
      },
      ImageUrl: "", //shumbnail src
      ImageUrl1: "",
      isRenewInsurance: false, //if tabledate has value show it
      cust_code: "", //radio id
      selection: {}, //radio current row
      renewTableData: [
        /*   {
          cust_code: "1123",
          order_no: "D20210910100139264655844",
          after_prem: "43495.54",
          start_time: "2021-09-09",
          end_time: "2022-09-08",
          policy_no: "12345677",
          holder_name: "中惠保险经纪有限公司",
          insurer_name: "中惠保险经纪有限公司",
        },
        {
          cust_code: "1123",
          order_no: "D20210910100139264655844",
          after_prem: "43495.54",
          start_time: "2021-09-09",
          end_time: "2022-09-08",
          policy_no: "12345677",
          holder_name: "中惠保险经纪有限公司",
          insurer_name: "中惠保险经纪有限公司",
        }, */
      ], //radio table

      isLock: false,
      isShowTable: false,
      isShowSubmit: false,

      checked: false,
      applicantInfo: {
        address: "",
        idcard: "",
        imgSrc: "",
        link_province: 0,
        link_city: 0,
        link_area: 0,
        link_name: "",
        link_phone: "",
        link_address: "",
        link_p_c_a_ch: "",
        p_c_a_ch: "", //省市区
      },
      rule_list: [],
      program_list: [],
      risk_list: [],
      formData: {
        additionalRisk1Limit: "",
        additionalRisk2Limit: "",
        additionalRisk3Limit: "",
        additionalRisk1Factor: "",
        additionalRisk2Factor: "",
        additionalRisk3Factor: "",
        is_social_security: "",
        file_code: "", //upload image id
        file_code1: "",
        dutyLimit: "",
        name: "",
        phoneNum: "",
        peopleNum: 0,
        address1: "", //中国区域地址
        address2: "", //家地址
        range: "", //主要经营范围
        code: "", //社会信用代码
        start_date: null,
        end_date: "",
        checkList: "", //企业风险系数
        personDutyLimit: "", //每人责任限额调整因子（广西）
        reparationFactor: "", //以往赔偿记录调整因子
        dangerSourceFactor: "", //重大危险源调整因子
        manageLevelFactor: "", //安全管理水平调整因子
        exampleFactor: "", //安全文化建设示范企业因子
        mail_type: "1", //保单类型
        policy_audit: "1",
        factor19YZ261411: "", //企业安全生产标准化因子
        factor19YZ336205: "", //月数（生产、批发、零售经营者）
        factor19YZ573684: "", //季节性零售月数
        factor19YZ866532: "", //每人责任限额调整因子（补充雇主）
        factor19YZ481969: "", //每人责任限额调整因子（补充公众）
        factor19YZ494699: "", //每人责任限额调整因子（燃放责任保险）
        /* 联系人 企业性质 + */
        business_nature: [], //经营性质 单位性质
        link_name: "", //被保企业联系人
        mobile: "", //被保企业联系方式
        link_p_c_a_code: [], //被保企业联系地址 1
        link_address: "", //被保企业联系地址 2
        /* 联系人 企业性质 + */
        docAddress: "",
      },
      addressOptions: regionData, //地址
      /* updata */
      fileList: [],
      fileList1: [],
      /* 每次及累计责任限额 */
      dutyLimitOptions: [],
      //每次及累计责任限额
      selectedDutyLimitItem: {},
      //每人责任限额调整因子（广西）
      selectedPersonDutyLimitItem: {},
      dutyLimitLimit: {},
      risk_code: "",
      risk_code1: "",
      /* 每人责任限额调整因子（广西） */
      personDutyLimitOptions: [],
      /*  以往赔偿记录调整因子*/
      reparationFactorOptions: [],
      /* 重大危险源调整因子 */
      dangerSourceFactorOptions: [],
      /*  安全管理水平调整因子*/
      manageLevelFactorOptions: [],
      /* 安全文化建设示范企业因子 */
      exampleFactorOptions: [],
      /* 企业风险系数 */
      companyRiskFactorList: [],
      factor19YZ261411Options: [], //企业安全生产标准化因子
      factor19YZ336205Options: [], //月数（生产、批发、零售经营者）
      factor19YZ573684Options: [], //季节性零售月数
      factor19YZ866532Options: [], //每人责任限额调整因子（补充雇主）
      factor19YZ481969Options: [], //每人责任限额调整因子（补充公众）
      factor19YZ494699Options: [], //每人责任限额调整因子（燃放责任保险）
      tableData: [
        {
          type: "q",
          num: "w",
          ratio: "e",
          total: "r",
        },
      ],
      premium_memo: "", //计算公式

      is_group: "1", //是否团体 默认值 '1' 目前写固定值
      submitData: {
        is_renewal: "1", //续保 先默认1
        renewal_policy_no: "", //
        product_code: this.$route.query.product_code,
        application_date: "", //提交时间 这个要自己生成一个
        RESUBTOKEN: "",
        p_c_a_code: "",
        p_c_a_name: "",
        holder: {},
        insureds: [],
        factors: [],
        risk: [],
      },
      uploadFile: {},
      uploadFile1: {},
      insuredAddress: {
        path: "",
        pathLabels: "",
      },
      mailAddress: {
        path: "",
        pathLabels: "",
      },
    };
  },
  mounted() {
    this.getData();
    this.getUserDetail();

    this.getOptions2();
  },
  methods: {
    validatestart_date(rule, value, callback) {
      if (this.formData.start_date === null) {
        return callback(new Error("保险日期需要选择"));
      } else {
        callback();
      }
    },
    validateSocialCode(rule, value, callback) {
      if (this.formData.code == "") {
        return callback(new Error("不能为空"));
      } else if (this.formData.code.length != 18) {
        return callback(new Error("社会信用代码需要输入18位"));
      } else {
        this.getRenewData();
        callback();
      }
    },
    //下拉 地区
    handleChangeLink_p_c_a_name() {
      var link_p_c_a_name = this.$refs.link_p_c_a_name.getCheckedNodes();
      this.formData.link_p_c_a_name = link_p_c_a_name[0].pathLabels.toString();
    },
    handleChangeBusiness_nature_des() {
      //企业性质转换

      var business_nature_des = this.$refs.business_nature_des.getCheckedNodes();
      this.formData.business_nature_des = business_nature_des[0].pathLabels.toString();
    },
    getOptions2() {
      //get 企业性质
      this.axios.get(`/api/v1/enterprise/getElement?type=2`).then((res) => {
        this.businessTypeOptions = res.data.result;
      });
    },
    changeRow(row) {
      //续保

      this.radioOrderNo = row.order_no;
      this.selectedRadio = row;
    },
    closeDialog() {
      this.isShow = false;
    },

    validateNum(rule, value, callback) {
      if (this.formData.peopleNum == 0) {
        return callback(new Error("企业人数不能为0"));
      } else {
        callback();
      }
    },
    validateUpload(rule, value, callback) {
      if (this.formData.file_code == "") {
        return callback(new Error("上传图片不能为空"));
      } else {
        callback();
      }
    },
    validatedocAddress(rule, value, callback) {
      if (this.formData.docAddress == "") {
        return callback(new Error("邮箱不能为空"));
      }
      if (this.formData.docAddress) {
        var at = this.formData.docAddress.indexOf("@");
        var dot = this.formData.docAddress.indexOf(".");
        var lon = this.formData.docAddress.length;
        if (at >= 0 && dot >= 0 && lon >= 5 && dot - at >= 2 && lon < 100) {
          callback();
        } else {
          return callback(new Error("请输入正确邮箱格式"));
        }
      }
      callback();
    },
    validatedocAddress1(rule, value, callback) {
      if (!this.formData.docAddress1 || !this.formData.docAddress2) {
        return callback(new Error("邮寄地址不能为空"));
      } else {
        callback();
      }
    },
    validateAddress(rule, value, callback) {
      if (!this.formData.address1 || !this.formData.address2) {
        return callback(new Error("被保企业地址不能为空"));
      } else {
        callback();
      }
    },
    changeDataPicker() {
      var n = this.formData.factor19YZ336205;
      if (n) {
        this.formData.end_date =
          this.$moment(this.formData.start_date)
            .add(n, "months")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD") + " 23:59:59";
      } else {
        this.formData.end_date =
          this.$moment(this.formData.start_date)
            .add(1, "years")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD") + " 23:59:59";
      }
    },
    uploadSuccess(res, file, fileList) {
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.ImageUrl = URL.createObjectURL(file.raw);
        this.uploadFile = {
          file_type: res.result[0].file_type,
          file_name: res.result[0].file_name,
          value: "",
          file_code: res.result[0].file_code,
        };
        this.formData.file_code = res.result[0].file_code;
      }
    },
    uploadSuccess1(res, file, fileList) {
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.ImageUrl1 = URL.createObjectURL(file.raw);
        this.uploadFile1 = {
          file_type: res.result[0].file_type,
          file_name: res.result[0].file_name,
          value: "",
          file_code: res.result[0].file_code,
        };
        this.formData.file_code1 = res.result[0].file_code;
      }
    },
    uploadError(err, file, fileList) {
      this.$message.warning(err);
    },
    exceed(files, fileList) {
      this.$message({
        message: "上传文件不能超过1个",
        type: "warning",
      });
    },
    beforeUpload(file) {
      if (
        file.type.indexOf("png") == -1 &&
        file.type.indexOf("jpg") == -1 &&
        file.type.indexOf("jpeg") == -1
      ) {
        this.$message({
          message: "上传文件格式只能png或jpg",
          type: "warning",
        });
        return false;
      } else if (file.size > 2000000) {
        this.$message({
          message: "上传文件大小不超过2M",
          type: "warning",
        });

        return false;
      }
    },
    handleChange(file, fileList) {
      this.fileList = [];
      this.fileList.push(file);
      //blFileList
    },
    handleChange_1(file, fileList) {
      this.fileList1 = [];
      this.fileList1.push(file);
      //blFileList
    },

    /* address select */
    handleChange1(v) {
      this.insuredAddress = {
        path: this.$refs.cascader1.getCheckedNodes()[0].path.toString(),
        pathLabels: this.$refs.cascader1
          .getCheckedNodes()[0]
          .pathLabels.toString(),
      };
    },
    handleChange2(v) {
      this.mailAddress = {
        path: this.$refs.cascader2.getCheckedNodes()[0].path.toString(),
        pathLabels: this.$refs.cascader2
          .getCheckedNodes()[0]
          .pathLabels.toString(),
      };
    },
    //计算
    countFactor() {
      if (this.isRenewInsurance) {
        if (!this.radioOrderNo) {
          this.$message({
            message: "需要选择续保~",
            type: "error",
          });
          return;
        }
      }

      this.$refs["formData"].validate((valid) => {
        if (this.formData.start_date === null) {
          this.$message({
            message: "需要选择保险期间",
            type: "error",
          });
          return;
        }

        if (valid) {
          var data = {
            product_code: this.$route.query.product_code,
            policy_start_date:
              this.$moment(this.formData.start_date).format("YYYY-MM-DD") +
              " 00:00:00",
            insurance_vos: [],
            factor_infos: [],
            renewal_status: this.isRenewInsurance ? "0" : "1", //1是新  0是续保额
            policy_no: this.isRenewInsurance
              ? this.selectedRadio.policy_no
              : "",
            industry_code: this.$store.state.industry,
          };

          /* 企业风险系数  7264*/
          //industry: "M13,M1301,M130101"

          data.factor_infos.push({
            factor_code: "YZ8213",
            factor_value: this.companyRiskFactorList.industry,
          });

          /* 每人责任限额调整因子（广西） */
          if (this.formData.personDutyLimit) {
            for (
              var j = 0;
              j < this.personDutyLimitOptions.coefficient.length;
              j++
            ) {
              if (
                this.formData.personDutyLimit ==
                this.personDutyLimitOptions.coefficient[j].code
              ) {
                data.factor_infos.push({
                  factor_code: this.personDutyLimitOptions.factor_code,
                  factor_value: this.personDutyLimitOptions.coefficient[j].code,
                });
                break;
              }
            }
          }

          /* 以往赔偿记录调整因子 */
          if (this.formData.reparationFactor) {
            for (
              var j = 0;
              j < this.reparationFactorOptions.coefficient.length;
              j++
            ) {
              if (
                this.formData.reparationFactor ==
                this.reparationFactorOptions.coefficient[j].code
              ) {
                data.factor_infos.push({
                  factor_code: this.reparationFactorOptions.factor_code,
                  factor_value: this.reparationFactorOptions.coefficient[j]
                    .code,
                });
                break;
              }
            }
          }

          /* 重大危险源调整因子 */
          if (this.formData.dangerSourceFactor) {
            for (
              var j = 0;
              j < this.dangerSourceFactorOptions.coefficient.length;
              j++
            ) {
              if (
                this.formData.dangerSourceFactor ==
                this.dangerSourceFactorOptions.coefficient[j].code
              ) {
                data.factor_infos.push({
                  factor_code: this.dangerSourceFactorOptions.factor_code,
                  factor_value: this.dangerSourceFactorOptions.coefficient[j]
                    .code,
                });
                break;
              }
            }
          }

          /* 安全管理水平调整因子(煤矿) */
          if (this.formData.manageLevelFactor) {
            for (
              var j = 0;
              j < this.manageLevelFactorOptions.coefficient.length;
              j++
            ) {
              if (
                this.formData.manageLevelFactor ==
                this.manageLevelFactorOptions.coefficient[j].code
              ) {
                data.factor_infos.push({
                  factor_code: this.manageLevelFactorOptions.factor_code,
                  factor_value: this.manageLevelFactorOptions.coefficient[j]
                    .code,
                });
                break;
              }
            }
          }

          /* 安全文化建设示范企业因子 */
          if (this.formData.exampleFactor) {
            for (
              var j = 0;
              j < this.exampleFactorOptions.coefficient.length;
              j++
            ) {
              if (
                this.formData.exampleFactor ==
                this.exampleFactorOptions.coefficient[j].code
              ) {
                data.factor_infos.push({
                  factor_code: this.exampleFactorOptions.factor_code,
                  factor_value: this.exampleFactorOptions.coefficient[j].code,
                });
                break;
              }
            }
          }

          /* 企业安全生产标准化因子 */
          if (this.formData.factor19YZ261411) {
            for (
              var j = 0;
              j < this.factor19YZ261411Options.coefficient.length;
              j++
            ) {
              if (
                this.formData.factor19YZ261411 ==
                this.factor19YZ261411Options.coefficient[j].code
              ) {
                data.factor_infos.push({
                  factor_code: this.factor19YZ261411Options.factor_code,
                  factor_value: this.factor19YZ261411Options.coefficient[j]
                    .code,
                });
                break;
              }
            }
          }

          /* 月数（生产、批发、零售经营者） */

          if (this.formData.factor19YZ336205) {
            for (
              var j = 0;
              j < this.factor19YZ336205Options.coefficient.length;
              j++
            ) {
              if (
                this.formData.factor19YZ336205 ==
                this.factor19YZ336205Options.coefficient[j].code
              ) {
                data.factor_infos.push({
                  factor_code: this.factor19YZ336205Options.factor_code,
                  factor_value: this.factor19YZ336205Options.coefficient[j]
                    .code,
                });

                //季节性传值
                if (this.companyRiskFactorList.industry == "M13020401") {
                  data.factor_infos.push({
                    factor_code: this.factor19YZ573684Options.factor_code,
                    factor_value: "",
                  });
                } else if (this.companyRiskFactorList.industry == "M13020402") {
                  data.factor_infos.push({
                    factor_code: this.factor19YZ573684Options.factor_code,
                    factor_value: this.factor19YZ336205Options.coefficient[j]
                      .code,
                  });
                }

                break;
              }
            }
          }

          /* 季节性零售月数 */
          /* 
          if (this.formData.factor19YZ573684) {
            for (
              var j = 0;
              j < this.factor19YZ573684Options.coefficient.length;
              j++
            ) {
              if (
                this.formData.factor19YZ573684 ==
                this.factor19YZ573684Options.coefficient[j].code
              ) {
                data.factor_infos.push({
                  factor_code: this.factor19YZ573684Options.factor_code,
                  factor_value:
                    this.factor19YZ573684Options.coefficient[j].code,
                });
                break;
              }
            }
          }
 */

          /* 每人责任限额调整因子（补充雇主） 1*/

          /* XEZ5847  限额 */

          if (this.formData.additionalRisk1Factor) {
            for (
              var j = 0;
              j < this.additionalRisk1FactorOptions.coefficient.length;
              j++
            ) {
              if (
                this.formData.additionalRisk1Factor ==
                this.additionalRisk1FactorOptions.coefficient[j].code
              ) {
                data.factor_infos.push({
                  factor_code: this.additionalRisk1FactorOptions.factor_code,
                  factor_value: this.additionalRisk1FactorOptions.coefficient[j]
                    .code,
                });
                break;
              }
            }
          }

          /* 每人责任限额调整因子（燃放责任保险） 2*/

          if (this.formData.additionalRisk2Factor) {
            for (
              var j = 0;
              j < this.additionalRisk2FactorOptions.coefficient.length;
              j++
            ) {
              if (
                this.formData.additionalRisk2Factor ==
                this.additionalRisk2FactorOptions.coefficient[j].code
              ) {
                data.factor_infos.push({
                  factor_code: this.additionalRisk2FactorOptions.factor_code,
                  factor_value: this.additionalRisk2FactorOptions.coefficient[j]
                    .code,
                });
                break;
              }
            }
          }

          /* 每人责任限额调整因子（补充公众）3 */
          if (this.formData.additionalRisk3Factor) {
            for (
              var j = 0;
              j < this.additionalRisk3FactorOptions.coefficient.length;
              j++
            ) {
              if (
                this.formData.additionalRisk3Factor ==
                this.additionalRisk3FactorOptions.coefficient[j].code
              ) {
                data.factor_infos.push({
                  factor_code: this.additionalRisk3FactorOptions.factor_code,
                  factor_value: this.additionalRisk3FactorOptions.coefficient[j]
                    .code,
                });
                break;
              }
            }
          }

          data.factor_infos.push({
            factor_code: "YZ8888",
            factor_value: this.formData.is_social_security,
          });
          this.submitData.factors = data.factor_infos;

          /* 每次及累计责任限额 */

          for (var i = 0; i < this.program_list.length; i++) {
            if (this.formData.dutyLimit == this.program_list[i].prog_code) {
              this.dutyLimitLimit = this.program_list[i];
              data.insurance_vos.push({
                count: 1,
                program_code: this.formData.dutyLimit,
                risk_beans: [
                  {
                    risk_code: this.program_list[i].liability_list[0].risk_code,
                  },
                ],
              });
              break;
            }
          }
          //附加险
          /*  附加补充雇主责任险  1*/

          if (this.additionalRisk1) {
            for (var i = 0; i < this.program_list.length; i++) {
              if (
                this.formData.additionalRisk1Limit ==
                this.program_list[i].prog_code
              ) {
                data.insurance_vos.push({
                  count: 1,
                  program_code: this.formData.additionalRisk1Limit,
                  risk_beans: [
                    {
                      risk_code: this.program_list[i].liability_list[0]
                        .risk_code,
                    },
                  ],
                });

                break;
              }
            }
          }
          /*  附加燃放责任保险  2*/
          if (this.additionalRisk2) {
            for (var i = 0; i < this.program_list.length; i++) {
              if (
                this.formData.additionalRisk2Limit ==
                this.program_list[i].prog_code
              ) {
                data.insurance_vos.push({
                  count: 1,
                  program_code: this.formData.additionalRisk2Limit,
                  risk_beans: [
                    {
                      risk_code: this.program_list[i].liability_list[0]
                        .risk_code,
                    },
                  ],
                });
                break;
              }
            }
          }
          /*  附加补充公众责任险  3*/
          if (this.additionalRisk3) {
            for (var i = 0; i < this.program_list.length; i++) {
              if (
                this.formData.additionalRisk3Limit ==
                this.program_list[i].prog_code
              ) {
                data.insurance_vos.push({
                  count: 1,
                  program_code: this.formData.additionalRisk3Limit,
                  risk_beans: [
                    {
                      risk_code: this.program_list[i].liability_list[0]
                        .risk_code,
                    },
                  ],
                });
                break;
              }
            }
          }

          /* 续保 新保 */

          this.axios({
            url: "/api/v1/prod/calPremiums",
            method: "post",
            data: {
              ...data,
            },
          }).then((res) => {
            if (res.data.code > 0) {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            } else {
              //公式
              this.premium_formula =res.data.result.single_premiums.premium_formula
             
             
              //公式说明
              this.premium_memo = res.data.result.single_premiums.premium_memo;

              this.tableData = [
                {
                  risk_name: "安责险保费",
                  risk_premiuml: res.data.result.total_premium,
                  rate: res.data.result.discount_rate,
                  diff: res.data.result.difference_premium,
                },
              ];
              //总保费
              this.total_premium =(Number(res.data.result.total_premium)-Number(res.data.result.difference_premium)).toFixed(2) ;

              this.isShowSubmit = true;
              this.isShowTable = true;
              this.steps = 1;
              this.security = res.data.result.security;
            }
          });
        } /*  else {
          this.$message({
            message: "请先完善表单内容,再按提交计算",
            type: "error",
          });
        } */
      });
    },
    handleRemove(file, fileList) {
      this.ImageUrl = "";
    },
    handleRemove1(file, fileList) {
      this.ImageUrl1 = "";
    },

    onSubmit1() {
      //展示dialog 详情
      this.$refs["formData"].validate((res) => {
        if (res) {
          this.isShow = true;
          var business_nature_des = this.$refs.business_nature_des.getCheckedNodes();
          this.formData.business_nature_des = business_nature_des[0].pathLabels.toString();
          var link_p_c_a_name = this.$refs.link_p_c_a_name.getCheckedNodes();
          this.formData.link_p_c_a_name = link_p_c_a_name[0].pathLabels.toString();
        }
      });
    },
    validatelink_address(rule, value, callback) {
      if (!this.formData.link_p_c_a_code || !this.formData.link_address) {
        return callback(new Error("请输入联系人地址"));
      } else if (this.formData.link_address.length > 500) {
        return callback(new Error("输入太多的字符了"));
      } else {
        callback();
      }
    },
    //提交
    onSubmit() {
      this.axios({
        url: "/api/v1/user/resubToken",
      }).then((res) => {
        //当前时间
        var application_date = this.$moment().format("YYYY-MM-DD HH:mm:ss");

        this.submitData.application_date = application_date;
        this.submitData.end_date = this.formData.end_date;
        this.submitData.start_date =
          this.$moment(this.formData.start_date).format("YYYY-MM-DD") +
          " 00:00:00";
        this.submitData.RESUBTOKEN = res.data;

        this.submitData.p_c_a_code = `${this.applicantInfo.province},${this.applicantInfo.city},${this.applicantInfo.area}`;

        this.submitData.p_c_a_name = this.applicantInfo.p_c_a_ch.replace(
          /\-/g,
          ","
        );

        this.submitData.policy_audit = this.formData.policy_audit;

        this.submitData.mail_type = this.formData.mail_type;

        if (this.formData.mail_type == "1") {
          this.submitData.mail_address = this.formData.docAddress;
        } else if (this.formData.mail_type == "2") {
          this.submitData.mail_address =
            this.$refs.cascader2.getCheckedNodes()[0].pathLabels.toString() +
            " " +
            this.formData.docAddress2;
        }

        this.submitData.holder = {
          name: this.applicantInfo.com_name, //投保企业名
          link_email: "", //联系人email
          holder_idcard: this.applicantInfo.idcard, //投保企业 idcard 统一社会信用代码
          p_c_a_code: this.submitData.p_c_a_code, //投保企业 地址 code
          p_c_a_name: this.submitData.p_c_a_name, //投保企业 地址 title
          address: this.applicantInfo.address, //  投保企业 地址 详情
          industry: this.applicantInfo.industry, //投保企业(客户)类型 code
          industry_des: this.applicantInfo.industry_value, //投保企业(客户)类型 title
          is_group: "1", //是否团体 默认值 '1' 目前写固定值
          business_nature: this.applicantInfo.unit_nature,
          business_nature_des: this.applicantInfo.unit_nature_value,
          link_name: this.applicantInfo.link_name,
          link_phone: this.applicantInfo.link_phone,
          link_p_c_a_code:
            this.applicantInfo.link_province +
            "," +
            this.applicantInfo.link_city +
            "," +
            this.applicantInfo.area,
          link_p_c_a_name: this.applicantInfo.link_p_c_a_ch,
          link_address: this.applicantInfo.link_address,
        };
        this.submitData.insureds = [];

        this.submitData.insureds.push({
          is_social_security: this.formData.is_social_security, //社会工伤保险
          is_holder: this.checked ? "1" : "0", //是否是holder 是的话 投保人 和被保人 是同一人 不是的话 0

          link_email: "",

          name: this.formData.name, //姓名
          link_phone: this.formData.mobile, //联系方式

          p_c_a_code: this.formData.address1.toString(), //企业地址
          p_c_a_name: this.insuredAddress.pathLabels, //企业地址
          address: this.formData.address2, //企业地址

          business_scope: this.formData.range, //经营范围

          idcard: this.formData.code, //社会信用代码

          industry: this.applicantInfo.industry, //使用投保人的值
          industry_des: this.applicantInfo.industry_value, //使用投保人的值

          upload_file: [
            {
              file_type: this.uploadFile.file_type,
              name: this.uploadFile.file_name,
              value: this.formData.code,
              file_code: this.uploadFile.file_code,
            },
          ],

          employed_num: this.formData.peopleNum, //从业人数

          //单位性质 code ------
          business_nature: this.formData.business_nature.toString(),
          //单位性质 title ------
          business_nature_des: this.$refs.business_nature_des
            .getCheckedNodes()[0]
            .pathLabels.toString(),

          link_phone: this.formData.mobile, //联系人电话

          //联系人姓名
          link_name: this.formData.link_name,

          //联系人 省 市 地 code -----
          link_p_c_a_code: this.formData.link_p_c_a_code.toString(),
          //联系人 省 市 地 描述 --------
          link_p_c_a_name: this.$refs.link_p_c_a_name
            .getCheckedNodes()[0]
            .pathLabels.toString(),
          //联系人 地址 详情
          link_address: this.formData.link_address,
        });

        if (this.uploadFile1.file_code) {
          this.submitData.insureds[0].upload_file.push({
            file_type: this.uploadFile1.file_type,
            name: this.uploadFile1.file_name,
            value: "000a",
            file_code: this.uploadFile1.file_code,
          });
        }

        this.submitData.risk = [];
        this.submitData.risk.push({
          risk_code: this.dutyLimitLimit.liability_list[0].risk_code, //risk_code "19XZ345036"
          risk_flag: this.dutyLimitLimit.risk_flag, //risk_flag
          main_risk_code: this.dutyLimitLimit.liability_list[0].risk_code, //risk_code "19XZ345036" 相同的
          program_code: this.dutyLimitLimit.prog_code, //prog_code "19FA674418"
          duties: [
            {
              duty_desc: "",
              duty_name: this.dutyLimitLimit.liability_list[0].name, //"从业人员及第三者人身伤害赔偿责任"
              duty_code: this.dutyLimitLimit.liability_list[0].code, //"19ZR545012"
              duty_limits: [],
            },
          ],
        });

        for (var i = 0; i < this.program_list.length; i++) {
          if (
            this.selectedDutyLimitItem.code == this.program_list[i].prog_code
          ) {
            this.risk_code = this.program_list[i].liability_list[0].risk_code;
            break;
          }
        }
        var coefficient = {};
        for (var j = 0; j < this.risk_list.length; j++) {
          if (this.risk_list[j].code == this.risk_code) {
            coefficient = this.risk_list[j].coefficient[0];
          }
        }
        //get user selected value

        var arr = [];
        for (var k = 0; k < coefficient.limit_list.length; k++) {
          for (
            var o = 0;
            o < coefficient.limit_list[k].liability_limit_value.length;
            o++
          ) {
            if (
              coefficient.limit_list[k].liability_limit_value[o]
                .liability_limit_value == this.selectedDutyLimitItem.name
            ) {
              arr.push({
                code: coefficient.limit_list[k].liability_limit_code, //insurance_liability_code
                name: coefficient.limit_list[k].liability_limit_name, //insurance_liability_name
                value: this.selectedDutyLimitItem.name,
                value_code:
                  coefficient.limit_list[k].liability_limit_value[o]
                    .liability_limit_value_code,
              });
            }
          }
        }

        //计算每人责任限额调整因子（广西）的risk

        var name = this.selectedPersonDutyLimitItem.name;
        name = name.replace(/万元/, "");

        var arr1 = [];
        for (
          var i = 0;
          i < this.risk_list[0].coefficient[0].limit_list.length;
          i++
        ) {
          for (
            var j = 0;
            j <
            this.risk_list[0].coefficient[0].limit_list[i].liability_limit_value
              .length;
            j++
          ) {
            if (
              this.selectedPersonDutyLimitItem.code ==
              this.risk_list[0].coefficient[0].limit_list[i]
                .liability_limit_value[j].liability_limit_value_code
            ) {
              arr1.push({
                code: this.risk_list[0].coefficient[0].limit_list[i]
                  .liability_limit_code, //insurance_liability_code
                name: this.risk_list[0].coefficient[0].limit_list[i]
                  .liability_limit_name, //insurance_liability_name
                value: name * 10000,
                value_code: this.risk_list[0].coefficient[0].limit_list[i]
                  .liability_limit_value[j].liability_limit_value_code,
              });
            }
          }
        }

        this.submitData.risk[0].duties[0].duty_limits = [...arr, ...arr1];

        //主险 code 换成 值
        var dutyLimitObj = {
          code: this.formData.dutyLimit,
          name: "",
        };
        //主险 因子
        var personDutyLimitObj = {
          code: this.formData.personDutyLimit,
          name: "",
        };

        for (var i = 0; i < this.dutyLimitOptions.length; i++) {
          if (this.dutyLimitOptions[i].code == this.formData.dutyLimit) {
            dutyLimitObj.name = this.dutyLimitOptions[i].name;
            break;
          }
        }

        for (
          var i = 0;
          i < this.personDutyLimitOptions.coefficient.length;
          i++
        ) {
          if (
            this.personDutyLimitOptions.coefficient[i].code ==
            this.formData.personDutyLimit
          ) {
            personDutyLimitObj.name = this.personDutyLimitOptions.coefficient[
              i
            ].name;
            break;
          }
        }

        if (personDutyLimitObj.name.indexOf("万元") >= 0) {
          personDutyLimitObj.name =
            personDutyLimitObj.name.replace("万元", "") * 10000;
        }

        var duty_limits = []; //主险 额度 因子额度

        for (var i = 0; i < this.risk_list.length; i++) {
          if (this.risk_list[i].risk_flag == "M") {
            for (
              var j = 0;
              j < this.risk_list[i].coefficient[0].limit_list.length;
              j++
            ) {
              if (
                this.risk_list[i].coefficient[0].limit_list[j].link_flag == "FA"
              ) {
                //方案 一种 直接拿值 一种 要算
                if (
                  this.risk_list[i].coefficient[0].limit_list[j]
                    .liability_limit_value.length == 1 &&
                  this.risk_list[i].coefficient[0].limit_list[j]
                    .liability_limit_value[0].calculation_rate
                ) {
                  //算

                  duty_limits.push({
                    code: this.risk_list[i].coefficient[0].limit_list[j]
                      .liability_limit_code,
                    name: this.risk_list[i].coefficient[0].limit_list[j]
                      .liability_limit_name,
                    value:
                      dutyLimitObj.name *
                      this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_value[0].calculation_rate,
                    value_code: this.risk_list[i].coefficient[0].limit_list[j]
                      .liability_limit_value[0].liability_limit_value_code,
                  });
                } else {
                  for (
                    var k = 0;
                    k <
                    this.risk_list[i].coefficient[0].limit_list[j]
                      .liability_limit_value.length;
                    k++
                  ) {
                    if (
                      dutyLimitObj.name ==
                      this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_value[k].liability_limit_value
                    ) {
                      duty_limits.push({
                        code: this.risk_list[i].coefficient[0].limit_list[j]
                          .liability_limit_code,
                        name: this.risk_list[i].coefficient[0].limit_list[j]
                          .liability_limit_name,
                        value: dutyLimitObj.name,
                        value_code: this.risk_list[i].coefficient[0].limit_list[
                          j
                        ].liability_limit_value[k].liability_limit_value_code,
                      });
                    }
                  }
                }
              } else {
                // 因子
                if (
                  this.risk_list[i].coefficient[0].limit_list[j]
                    .liability_limit_value.length == 1 &&
                  this.risk_list[i].coefficient[0].limit_list[j]
                    .liability_limit_value[0].calculation_rate
                ) {
                  var result1 = this.applicantInfo.industry.indexOf("M111001");
                  var result2 = this.applicantInfo.industry.indexOf("M1001");

                  if (
                    (this.risk_list[i].coefficient[0].limit_list[j]
                      .liability_limit_value[0].calculation_rate_bak &&
                      result1 != -1) ||
                    result2 != -1
                  ) {
                    duty_limits.push({
                      code: this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_code,
                      name: this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_name,
                      value:
                        personDutyLimitObj.name *
                        this.risk_list[i].coefficient[0].limit_list[j]
                          .liability_limit_value[0].calculation_rate_bak,
                      value_code: this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_value[0].liability_limit_value_code,
                    });
                  } else {
                    //算
                    duty_limits.push({
                      code: this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_code,
                      name: this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_name,
                      value:
                        personDutyLimitObj.name *
                        this.risk_list[i].coefficient[0].limit_list[j]
                          .liability_limit_value[0].calculation_rate,
                      value_code: this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_value[0].liability_limit_value_code,
                    });
                  }
                } else {
                  for (
                    var k = 0;
                    k <
                    this.risk_list[i].coefficient[0].limit_list[j]
                      .liability_limit_value.length;
                    k++
                  ) {
                    if (
                      personDutyLimitObj.name ==
                      this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_value[k].liability_limit_value
                    ) {
                      duty_limits.push({
                        code: this.risk_list[i].coefficient[0].limit_list[j]
                          .liability_limit_code,
                        name: this.risk_list[i].coefficient[0].limit_list[j]
                          .liability_limit_name,
                        value: personDutyLimitObj.name,
                        value_code: this.risk_list[i].coefficient[0].limit_list[
                          j
                        ].liability_limit_value[k].liability_limit_value_code,
                      });
                    }
                  }
                }
              }
            }
          }
        }
        this.submitData.risk[0].duties[0].duty_limits = duty_limits;

        /* 附加险 */
        //放入this.submitData.risk数组
        if (this.additionalRisk1) {
          //附加险 1
          var additionalRisk1LimitObj = {
            code: this.formData.additionalRisk1Limit,
            name: "",
          };
          //附加险 因子 1
          var additionalRisk1FactorObj = {
            code: this.formData.additionalRisk1Factor,
            name: "",
          };

          for (var i = 0; i < this.additionalRisk1LimitOptions.length; i++) {
            if (
              this.additionalRisk1LimitOptions[i].code ==
              this.formData.additionalRisk1Limit
            ) {
              additionalRisk1LimitObj.name = this.additionalRisk1LimitOptions[
                i
              ].name;
            }
          }
          if (additionalRisk1LimitObj.name.indexOf("元") >= 0) {
            additionalRisk1LimitObj.name = additionalRisk1LimitObj.name.replace(
              "元",
              ""
            );
          }

          // FA2336 附加险1
          for (var i = 0; i < this.risk_prog_list.length; i++) {
            if (this.risk_prog_list[i].risk_code == "XZ3167") {
              for (var j = 0; j < this.risk_list.length; j++) {
                if (this.risk_list[j].code == "XZ3167") {
                  this.submitData.risk.push({
                    risk_code: "XZ3167", //risk_code "19XZ345036"
                    risk_flag: "A", //risk_flag

                    main_risk_code: this.risk_prog_list[i].risk_code, //XZ3167

                    program_code: additionalRisk1LimitObj.code, //FA2336
                    duties: [
                      {
                        duty_desc: "",
                        duty_name: this.risk_list[j].coefficient[0]
                          .insurance_liability_name, //"从业人员及第三者人身伤害赔偿责任"
                        duty_code: this.risk_list[j].coefficient[0]
                          .insurance_liability_code, //"19ZR545012"
                        duty_limits: [],
                      },
                    ],
                  });
                }
              }
            }
          }

          //附加险1 duty_limits
          var duty_limits1 = [];

          for (var i = 0; i < this.risk_list.length; i++) {
            if (this.risk_list[i].code == "XZ3167") {
              for (
                var j = 0;
                j < this.risk_list[i].coefficient[0].limit_list.length;
                j++
              ) {
                if (
                  this.risk_list[i].coefficient[0].limit_list[j].link_flag ==
                  "FA"
                ) {
                  //方案 一种 直接拿值 一种 要算
                  if (
                    this.risk_list[i].coefficient[0].limit_list[j]
                      .liability_limit_value.length == 1 &&
                    this.risk_list[i].coefficient[0].limit_list[j]
                      .liability_limit_value[0].calculation_rate
                  ) {
                    //算
                    duty_limits1.push({
                      code: this.risk_list[i].coefficient[0]
                        .insurance_liability_code,
                      name: this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_name,
                      value:
                        additionalRisk1LimitObj.name *
                        this.risk_list[i].coefficient[0].limit_list[j]
                          .liability_limit_value[0].calculation_rate,
                      value_code: this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_value[0].liability_limit_value_code,
                    });
                  } else {
                    for (
                      var k = 0;
                      k <
                      this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_value.length;
                      k++
                    ) {
                      if (
                        additionalRisk1LimitObj.name ==
                        this.risk_list[i].coefficient[0].limit_list[j]
                          .liability_limit_value[k].liability_limit_value
                      ) {
                        duty_limits1.push({
                          code: this.risk_list[i].coefficient[0]
                            .insurance_liability_code,
                          name: this.risk_list[i].coefficient[0].limit_list[j]
                            .liability_limit_name,
                          value: additionalRisk1LimitObj.name,
                          value_code: this.risk_list[i].coefficient[0]
                            .limit_list[j].liability_limit_value[k]
                            .liability_limit_value_code,
                        });
                      }
                    }
                  }
                } else {
                  // 因子
                  if (
                    this.risk_list[i].coefficient[0].limit_list[j]
                      .liability_limit_value.length == 1 &&
                    this.risk_list[i].coefficient[0].limit_list[j]
                      .liability_limit_value[0].calculation_rate
                  ) {
                    //算
                    duty_limits1.push({
                      code: this.risk_list[i].coefficient[0]
                        .insurance_liability_code,
                      name: this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_name,
                      value:
                        additionalRisk1FactorObj.name *
                        this.risk_list[i].coefficient[0].limit_list[j]
                          .liability_limit_value[0].calculation_rate,
                      value_code: this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_value[0].liability_limit_value_code,
                    });
                  } else {
                    for (
                      var k = 0;
                      k <
                      this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_value.length;
                      k++
                    ) {
                      if (
                        additionalRisk1FactorObj.name ==
                        this.risk_list[i].coefficient[0].limit_list[j]
                          .liability_limit_value[k].liability_limit_value
                      ) {
                        duty_limits1.push({
                          code: this.risk_list[i].coefficient[0]
                            .insurance_liability_code,
                          name: this.risk_list[i].coefficient[0].limit_list[j]
                            .liability_limit_name,
                          value: additionalRisk1FactorObj.name,
                          value_code: this.risk_list[i].coefficient[0]
                            .limit_list[j].liability_limit_value[k]
                            .liability_limit_value_code,
                        });
                      }
                    }
                  }
                }
              }
            }
          }

          //放入附加险1
          for (var i = 0; i < this.submitData.risk.length; i++) {
            if (this.submitData.risk[i].risk_code == "XZ3167") {
              this.submitData.risk[i].duties[0].duty_limits = duty_limits1;
            }
          }
        }

        if (this.additionalRisk2) {
          //附加险 2
          var additionalRisk2LimitObj = {
            code: this.formData.additionalRisk2Limit,
            name: "",
          };
          //附加险 因子 2
          var additionalRisk2FactorObj = {
            code: this.formData.additionalRisk2Factor,
            name: "",
          };
          for (var i = 0; i < this.additionalRisk2LimitOptions.length; i++) {
            if (
              this.additionalRisk2LimitOptions[i].code ==
              this.formData.additionalRisk2Limit
            ) {
              additionalRisk2LimitObj.name = this.additionalRisk2LimitOptions[
                i
              ].name;
            }
          }
          if (additionalRisk2LimitObj.name.indexOf("元") >= 0) {
            additionalRisk2LimitObj.name = additionalRisk2LimitObj.name.replace(
              "元",
              ""
            );
          }

          // FA2336 附加险1

          for (var i = 0; i < this.risk_prog_list.length; i++) {
            if (this.risk_prog_list[i].risk_code == "XZ2992") {
              for (var j = 0; j < this.risk_list.length; j++) {
                if (this.risk_list[j].code == "XZ2992") {
                  this.submitData.risk.push({
                    risk_code: "XZ2992", //risk_code "19XZ345036"
                    risk_flag: "A", //risk_flag

                    main_risk_code: this.risk_prog_list[i].risk_code, //XZ3167

                    program_code: additionalRisk2LimitObj.code, //FA2336
                    duties: [
                      {
                        duty_desc: "",
                        duty_name: this.risk_list[j].coefficient[0]
                          .insurance_liability_name, //"从业人员及第三者人身伤害赔偿责任"
                        duty_code: this.risk_list[j].coefficient[0]
                          .insurance_liability_code, //"19ZR545012"
                        duty_limits: [],
                      },
                    ],
                  });
                }
              }
            }
          }

          //附加险1 duty_limits
          var duty_limits2 = [];

          for (var i = 0; i < this.risk_list.length; i++) {
            if (this.risk_list[i].code == "XZ2992") {
              for (
                var j = 0;
                j < this.risk_list[i].coefficient[0].limit_list.length;
                j++
              ) {
                if (
                  this.risk_list[i].coefficient[0].limit_list[j].link_flag ==
                  "FA"
                ) {
                  //方案 一种 直接拿值 一种 要算
                  if (
                    this.risk_list[i].coefficient[0].limit_list[j]
                      .liability_limit_value.length == 1 &&
                    this.risk_list[i].coefficient[0].limit_list[j]
                      .liability_limit_value[0].calculation_rate
                  ) {
                    //算
                    duty_limits2.push({
                      code: this.risk_list[i].coefficient[0]
                        .insurance_liability_code,
                      name: this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_name,
                      value:
                        additionalRisk2LimitObj.name *
                        this.risk_list[i].coefficient[0].limit_list[j]
                          .liability_limit_value[0].calculation_rate,
                      value_code: this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_value[0].liability_limit_value_code,
                    });
                  } else {
                    for (
                      var k = 0;
                      k <
                      this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_value.length;
                      k++
                    ) {
                      if (
                        additionalRisk2LimitObj.name ==
                        this.risk_list[i].coefficient[0].limit_list[j]
                          .liability_limit_value[k].liability_limit_value
                      ) {
                        duty_limits2.push({
                          code: this.risk_list[i].coefficient[0]
                            .insurance_liability_code,
                          name: this.risk_list[i].coefficient[0].limit_list[j]
                            .liability_limit_name,
                          value: additionalRisk2LimitObj.name,
                          value_code: this.risk_list[i].coefficient[0]
                            .limit_list[j].liability_limit_value[k]
                            .liability_limit_value_code,
                        });
                      }
                    }
                  }
                } else {
                  // 因子
                  if (
                    this.risk_list[i].coefficient[0].limit_list[j]
                      .liability_limit_value.length == 1 &&
                    this.risk_list[i].coefficient[0].limit_list[j]
                      .liability_limit_value[0].calculation_rate
                  ) {
                    //算
                    duty_limits2.push({
                      code: this.risk_list[i].coefficient[0]
                        .insurance_liability_code,
                      name: this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_name,
                      value:
                        additionalRisk2FactorObj.name *
                        this.risk_list[i].coefficient[0].limit_list[j]
                          .liability_limit_value[0].calculation_rate,
                      value_code: this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_value[0].liability_limit_value_code,
                    });
                  } else {
                    for (
                      var k = 0;
                      k <
                      this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_value.length;
                      k++
                    ) {
                      if (
                        additionalRisk2FactorObj.name ==
                        this.risk_list[i].coefficient[0].limit_list[j]
                          .liability_limit_value[k].liability_limit_value
                      ) {
                        duty_limits2.push({
                          code: this.risk_list[i].coefficient[0]
                            .insurance_liability_code,
                          name: this.risk_list[i].coefficient[0].limit_list[j]
                            .liability_limit_name,
                          value: additionalRisk2FactorObj.name,
                          value_code: this.risk_list[i].coefficient[0]
                            .limit_list[j].liability_limit_value[k]
                            .liability_limit_value_code,
                        });
                      }
                    }
                  }
                }
              }
            }
          }

          //放入附加险1
          for (var i = 0; i < this.submitData.risk.length; i++) {
            if (this.submitData.risk[i].risk_code == "XZ2992") {
              this.submitData.risk[i].duties[0].duty_limits = duty_limits2;
            }
          }
        }

        if (this.additionalRisk3) {
          //附加险 3
          var additionalRisk3LimitObj = {
            code: this.formData.additionalRisk3Limit,
            name: "",
          };
          //附加险 因子 3
          var additionalRisk3FactorObj = {
            code: this.formData.additionalRisk3Factor,
            name: "",
          };

          for (var i = 0; i < this.additionalRisk3LimitOptions.length; i++) {
            if (
              this.additionalRisk3LimitOptions[i].code ==
              this.formData.additionalRisk3Limit
            ) {
              additionalRisk3LimitObj.name = this.additionalRisk3LimitOptions[
                i
              ].name;
            }
          }
          if (additionalRisk3LimitObj.name.indexOf("元") >= 0) {
            additionalRisk3LimitObj.name = additionalRisk3LimitObj.name.replace(
              "元",
              ""
            );
          }

          // FA2336 附加险3

          for (var i = 0; i < this.risk_prog_list.length; i++) {
            if (this.risk_prog_list[i].risk_code == "XZ8783") {
              for (var j = 0; j < this.risk_list.length; j++) {
                if (this.risk_list[j].code == "XZ8783") {
                  this.submitData.risk.push({
                    risk_code: "XZ8783", //risk_code "19XZ345036"
                    risk_flag: "A", //risk_flag

                    main_risk_code: this.risk_prog_list[i].risk_code, //XZ3167

                    program_code: additionalRisk3LimitObj.code, //FA2336
                    duties: [
                      {
                        duty_desc: "",
                        duty_name: this.risk_list[j].coefficient[0]
                          .insurance_liability_name, //"从业人员及第三者人身伤害赔偿责任"
                        duty_code: this.risk_list[j].coefficient[0]
                          .insurance_liability_code, //"19ZR545012"
                        duty_limits: [],
                      },
                    ],
                  });
                }
              }
            }
          }

          //附加险1 duty_limits
          var duty_limits3 = [];

          for (var i = 0; i < this.risk_list.length; i++) {
            if (this.risk_list[i].code == "XZ8783") {
              for (
                var j = 0;
                j < this.risk_list[i].coefficient[0].limit_list.length;
                j++
              ) {
                if (
                  this.risk_list[i].coefficient[0].limit_list[j].link_flag ==
                  "FA"
                ) {
                  //方案 一种 直接拿值 一种 要算
                  if (
                    this.risk_list[i].coefficient[0].limit_list[j]
                      .liability_limit_value.length == 1 &&
                    this.risk_list[i].coefficient[0].limit_list[j]
                      .liability_limit_value[0].calculation_rate
                  ) {
                    //算
                    duty_limits3.push({
                      code: this.risk_list[i].coefficient[0]
                        .insurance_liability_code,
                      name: this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_name,
                      value:
                        additionalRisk3LimitObj.name *
                        this.risk_list[i].coefficient[0].limit_list[j]
                          .liability_limit_value[0].calculation_rate,
                      value_code: this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_value[0].liability_limit_value_code,
                    });
                  } else {
                    for (
                      var k = 0;
                      k <
                      this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_value.length;
                      k++
                    ) {
                      if (
                        additionalRisk3LimitObj.name ==
                        this.risk_list[i].coefficient[0].limit_list[j]
                          .liability_limit_value[k].liability_limit_value
                      ) {
                        duty_limits3.push({
                          code: this.risk_list[i].coefficient[0]
                            .insurance_liability_code,
                          name: this.risk_list[i].coefficient[0].limit_list[j]
                            .liability_limit_name,
                          value: additionalRisk3LimitObj.name,
                          value_code: this.risk_list[i].coefficient[0]
                            .limit_list[j].liability_limit_value[k]
                            .liability_limit_value_code,
                        });
                      }
                    }
                  }
                } else {
                  // 因子
                  if (
                    this.risk_list[i].coefficient[0].limit_list[j]
                      .liability_limit_value.length == 1 &&
                    this.risk_list[i].coefficient[0].limit_list[j]
                      .liability_limit_value[0].calculation_rate
                  ) {
                    //算
                    duty_limits3.push({
                      code: this.risk_list[i].coefficient[0]
                        .insurance_liability_code,
                      name: this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_name,
                      value:
                        additionalRisk3FactorObj.name *
                        this.risk_list[i].coefficient[0].limit_list[j]
                          .liability_limit_value[0].calculation_rate,
                      value_code: this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_value[0].liability_limit_value_code,
                    });
                  } else {
                    for (
                      var k = 0;
                      k <
                      this.risk_list[i].coefficient[0].limit_list[j]
                        .liability_limit_value.length;
                      k++
                    ) {
                      if (
                        additionalRisk3FactorObj.name ==
                        this.risk_list[i].coefficient[0].limit_list[j]
                          .liability_limit_value[k].liability_limit_value
                      ) {
                        duty_limits3.push({
                          code: this.risk_list[i].coefficient[0]
                            .insurance_liability_code,
                          name: this.risk_list[i].coefficient[0].limit_list[j]
                            .liability_limit_name,
                          value: additionalRisk3FactorObj.name,
                          value_code: this.risk_list[i].coefficient[0]
                            .limit_list[j].liability_limit_value[k]
                            .liability_limit_value_code,
                        });
                      }
                    }
                  }
                }
              }
            }
          }

          //放入附加险1
          for (var i = 0; i < this.submitData.risk.length; i++) {
            if (this.submitData.risk[i].risk_code == "XZ8783") {
              this.submitData.risk[i].duties[0].duty_limits = duty_limits3;
            }
          }
        }

        this.submitData.is_renewal = this.isRenewInsurance ? "0" : "1";
        this.submitData.renewal_policy_no = this.isRenewInsurance
          ? this.selectedRadio.policy_no
          : "";
        this.submitData.risk = this.security;
        var submitData = this.submitData;

        this.axios({
          url: "/api/v1/order/subOrder",
          method: "post",
          data: submitData,
        }).then((res) => {
          if (res.data.code > 0) {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: "success",
            });

            this.$store.dispatch("submitObj", res.data.result).then(() => {
              this.$router.push({
                path: "/product/confirm",
              });
            });
          }
        });
      });
    },

    getData() {
      this.axios
        .post("/api/v1/prod/getProductDetail", {
          product_code: this.$route.query.product_code,
        })
        .then((res) => {
          if (res.data.code > 0) {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          } else {
            var factor_list = res.data.result.factor_list;

            for (var i = 0; i < factor_list.length; i++) {
              if (factor_list[i].factor_code == "YZ8213") {
                //""企业风险系数""
              } else if (factor_list[i].factor_code == "YZ7264") {
                //每人责任限额调整因子（广西）
                this.personDutyLimitOptions = factor_list[i];
              } else if (factor_list[i].factor_code == "YZ7597") {
                //以往赔偿记录调整因子
                this.reparationFactorOptions = factor_list[i];
              } else if (factor_list[i].factor_code == "YZ7413") {
                //重大危险源调整因子
                this.dangerSourceFactorOptions = factor_list[i];
              } else if (factor_list[i].factor_code == "YZ8358") {
                //"安全管理水平调整因子(煤矿)"
                this.manageLevelFactorOptions = factor_list[i];
              } else if (factor_list[i].factor_code == "YZ7333") {
                //""安全文化建设示范企业因子"
                this.exampleFactorOptions = factor_list[i];
              } else if (factor_list[i].factor_code == "YZ7647") {
                //企业安全生产标准化因子
                this.factor19YZ261411Options = factor_list[i];
              } else if (factor_list[i].factor_code == "YZ7723") {
                //月数（生产、批发、零售经营者）

                if (this.companyRiskFactorList.industry == "M13020402") {
                  this.factor19YZ336205Options = factor_list[i];
                  this.factor19YZ336205Options.coefficient = [
                    { code: "1", name: "一个月" },
                    { code: "2", name: "二个月" },
                    { code: "3", name: "三个月" },
                  ];
                } else {
                  this.factor19YZ336205Options = factor_list[i];
                }
              } else if (factor_list[i].factor_code == "YZ0789") {
                //季节性零售月数
                this.factor19YZ573684Options = factor_list[i];
              } else if (factor_list[i].factor_code == "YZ0791") {
                //每人责任限额调整因子（补充雇主）
                this.additionalRisk1FactorOptions = factor_list[i];
              } else if (factor_list[i].factor_code == "YZ8044") {
                //每人责任限额调整因子（补充公众）
                this.additionalRisk3FactorOptions = factor_list[i];
              } else if (factor_list[i].factor_code == "YZ8145") {
                //每人责任限额调整因子（燃放责任保险）
                this.additionalRisk2FactorOptions = factor_list[i];
              }
            }

            this.rule_list = res.data.result.rule_list;
            this.program_list = res.data.result.program_list;

            this.risk_list = res.data.result.risk_list;
            this.risk_prog_list = res.data.result.risk_prog_list;
            //get 附加险 去掉主险
            var arr = res.data.result.risk_prog_list;
            var arr1 = [];
            for (var i = 0; i < arr.length; i++) {
              if (arr[i].risk_flag == "A") {
                arr[i].isShow = false;
                arr1.push(arr[i]);
              }
            }

            this.risk_prog_list = arr1;
            if (this.risk_prog_list.length > 0) {
              /* this.additionalRisk1 = true;
              this.additionalRisk2 = true;
              this.additionalRisk3 = true; */
            }
          }
        });
    },
    getRenewData() {
      this.axios({
        url: "/api/v1/order/getPolicyByCard",
        method: "get",
        params: {
          three_in_one_code: this.formData.code,
        },
      }).then((res) => {
        if (res.data.code > 0) {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
          this.errorStop.flag = true;
          this.errorStop.msg = res.data.msg;
        } else {
          this.renewTableData = res.data.result;
          this.errorStop.flag = false;
          this.errorStop.msg = "";

          if (this.renewTableData.length > 0) {
            this.isRenewInsurance = true;
          } else {
            this.isRenewInsurance = false;
          }
        }
      });
    },
    getUserDetail() {
      var userCode = this.$store.state.userCode;

      this.axios({
        url: "/api/v1/user/userDetails",
        method: "post",
        data: {
          user_code: userCode,
        },
      }).then((res) => {
        if (res.data.code > 0) {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        } else {
          this.applicantInfo = res.data.result;

          this.companyRiskFactorList = {
            industry_value: res.data.result.industry_value,
            industry: res.data.result.industry.split(",")[
              res.data.result.industry.split(",").length - 1
            ],
          };

          var arr = res.data.result.paperwork_info_list;
          for (var i = 0; i < arr.length; i++) {
            if (arr[i].type == "bl") {
              this.applicantInfo.idcard = arr[i].value;
              this.applicantInfo.imgSrc = arr[i].file_url;
            }
          }
        }
      });
    },
    changeDutyLimit(v) {
      for (var i = 0; i < this.dutyLimitOptions.length; i++) {
        if (v == this.dutyLimitOptions[i].code) {
          this.selectedDutyLimitItem = this.dutyLimitOptions[i];
          break;
        }
      }

      var n = this.formData.peopleNum;
      this.formData.additionalRisk1Limit = "";
      this.formData.additionalRisk2Limit = "";
      this.formData.additionalRisk3Limit = "";

      if (this.risk_prog_list.length > 0) {
        this.getXZ3167Options(n);
        this.getXZ2992Options(n);
        this.getXZ8783Options(n);
      }
    },
    getXZ3167Options(n) {
      //"附加补充雇主责任险"
      for (var i = 0; i < this.risk_prog_list.length; i++) {
        if (this.risk_prog_list[i].risk_code == "XZ3167") {
          for (
            var j = 0;
            j < this.risk_prog_list[i].rule_prog_list.length;
            j++
          ) {
            var arr = this.risk_prog_list[i].rule_prog_list[j].rule_value.split(
              ","
            );
            var flag1 = false;
            var flag2 = false;
            // 5$M13020401$,2$0$$5$
            for (var k = 0; k < arr.length; k++) {
              if (arr[k][0] == 5) {
                if (
                  this.$store.state.industry.split(",")[
                    this.$store.state.industry.split(",").length - 1
                  ] == arr[k].split("$")[1]
                ) {
                  flag1 = true;
                }
              } else if (arr[k][0] == 2 || arr[k][0] == 6) {
                var arr1 = arr[k].split("$");

                if (
                  n > (arr1[1] == "max" ? Infinity : arr1[1]) &&
                  n <= (arr1[3] == "max" ? Infinity : arr1[3])
                ) {
                  flag2 = true;
                }
              }

              if (arr.length > 1) {
                if (flag1 && flag2) {
                  var liability_limit_list = this.risk_prog_list[i]
                    .rule_prog_list[j].prog_spl_list;

                  this.additionalRisk1LimitOptions = [];
                  for (var m = 0; m < liability_limit_list.length; m++) {
                    if (
                      this.selectedDutyLimitItem.name <=
                      liability_limit_list[m].liability_limit_list[0]
                        .limit_values
                    ) {
                      this.additionalRisk1LimitOptions.push({
                        code: liability_limit_list[m].program_code,
                        name:
                          liability_limit_list[m].liability_limit_list[0]
                            .limit_values + "元",
                      });
                    }
                  }
                }
              } else {
                if (flag1 || flag2) {
                  var liability_limit_list = this.risk_prog_list[i]
                    .rule_prog_list[j].prog_spl_list;

                  this.additionalRisk1LimitOptions = [];
                  for (var m = 0; m < liability_limit_list.length; m++) {
                    if (
                      this.selectedDutyLimitItem.name <=
                      liability_limit_list[m].liability_limit_list[0]
                        .limit_values
                    ) {
                      this.additionalRisk1LimitOptions.push({
                        code: liability_limit_list[m].program_code,
                        name:
                          liability_limit_list[m].liability_limit_list[0]
                            .limit_values + "元",
                      });
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    getXZ2992Options(n) {
      //"附加燃放责任保险（适用于依法具有焰火燃放资质的企业）"

      for (var i = 0; i < this.risk_prog_list.length; i++) {
        if (this.risk_prog_list[i].risk_code == "XZ2992") {
          for (
            var j = 0;
            j < this.risk_prog_list[i].rule_prog_list.length;
            j++
          ) {
            var arr = this.risk_prog_list[i].rule_prog_list[j].rule_value.split(
              ","
            );

            var flag1 = false;
            var flag2 = false;
            // 5$M13020401$,2$0$$5$
            for (var k = 0; k < arr.length; k++) {
              if (arr[k][0] == 5) {
                if (
                  this.$store.state.industry.split(",")[
                    this.$store.state.industry.split(",").length - 1
                  ] == arr[k].split("$")[1]
                ) {
                  flag1 = true;
                }
              } else if (arr[k][0] == 2 || arr[k][0] == 6) {
                var arr1 = arr[k].split("$");

                if (
                  n > (arr1[1] == "max" ? Infinity : arr1[1]) &&
                  n <= (arr1[3] == "max" ? Infinity : arr1[3])
                ) {
                  flag2 = true;
                }
              }
              if (arr.length > 1) {
                if (flag1 && flag2) {
                  var liability_limit_list = this.risk_prog_list[i]
                    .rule_prog_list[j].prog_spl_list;

                  this.additionalRisk2LimitOptions = [];
                  for (var m = 0; m < liability_limit_list.length; m++) {
                    if (
                      this.selectedDutyLimitItem.name <=
                      liability_limit_list[m].liability_limit_list[0]
                        .limit_values
                    ) {
                      this.additionalRisk2LimitOptions.push({
                        code: liability_limit_list[m].program_code,
                        name:
                          liability_limit_list[m].liability_limit_list[0]
                            .limit_values + "元",
                      });
                    }
                  }
                }
              } else {
                if (flag1 || flag2) {
                  var liability_limit_list = this.risk_prog_list[i]
                    .rule_prog_list[j].prog_spl_list;

                  this.additionalRisk2LimitOptions = [];
                  for (var m = 0; m < liability_limit_list.length; m++) {
                    if (
                      this.selectedDutyLimitItem.name <=
                      liability_limit_list[m].liability_limit_list[0]
                        .limit_values
                    ) {
                      this.additionalRisk2LimitOptions.push({
                        code: liability_limit_list[m].program_code,
                        name:
                          liability_limit_list[m].liability_limit_list[0]
                            .limit_values + "元",
                      });
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    getXZ8783Options(n) {
      //"附加补充公众责任险"

      for (var i = 0; i < this.risk_prog_list.length; i++) {
        if (this.risk_prog_list[i].risk_code == "XZ8783") {
          for (
            var j = 0;
            j < this.risk_prog_list[i].rule_prog_list.length;
            j++
          ) {
            var arr = this.risk_prog_list[i].rule_prog_list[j].rule_value.split(
              ","
            );
            var flag1 = false;
            var flag2 = false;
            // 5$M13020401$,2$0$$5$
            for (var k = 0; k < arr.length; k++) {
              if (arr[k][0] == 5) {
                if (
                  this.$store.state.industry.split(",")[
                    this.$store.state.industry.split(",").length - 1
                  ] == arr[k].split("$")[1]
                ) {
                  flag1 = true;
                }
              } else if (arr[k][0] == 2 || arr[k][0] == 6) {
                var arr1 = arr[k].split("$");

                if (
                  n > (arr1[1] == "max" ? Infinity : arr1[1]) &&
                  n <= (arr1[3] == "max" ? Infinity : arr1[3])
                ) {
                  flag2 = true;
                }
              }

              if (arr.length > 1) {
                if (flag1 && flag2) {
                  var liability_limit_list = this.risk_prog_list[i]
                    .rule_prog_list[j].prog_spl_list;

                  this.additionalRisk3LimitOptions = [];
                  for (var m = 0; m < liability_limit_list.length; m++) {
                    if (
                      this.selectedDutyLimitItem.name <=
                      liability_limit_list[m].liability_limit_list[0]
                        .limit_values
                    ) {
                      this.additionalRisk3LimitOptions.push({
                        code: liability_limit_list[m].program_code,
                        name:
                          liability_limit_list[m].liability_limit_list[0]
                            .limit_values + "元",
                      });
                    }
                  }
                }
              } else {
                if (flag1 || flag2) {
                  var liability_limit_list = this.risk_prog_list[i]
                    .rule_prog_list[j].prog_spl_list;

                  this.additionalRisk3LimitOptions = [];
                  for (var m = 0; m < liability_limit_list.length; m++) {
                    if (
                      this.selectedDutyLimitItem.name <=
                      liability_limit_list[m].liability_limit_list[0]
                        .limit_values
                    ) {
                      this.additionalRisk3LimitOptions.push({
                        code: liability_limit_list[m].program_code,
                        name:
                          liability_limit_list[m].liability_limit_list[0]
                            .limit_values + "元",
                      });
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    changePersonDutyLimit(v) {
      for (var i = 0; i < this.personDutyLimitOptions.coefficient.length; i++) {
        if (v == this.personDutyLimitOptions.coefficient[i].code) {
          this.selectedPersonDutyLimitItem = this.personDutyLimitOptions.coefficient[
            i
          ];
          break;
        }
      }
    },
    changeRadio(row) {
      //监听change 赋值
      this.radioId = row.id;
      this.selection = row;
    },
    handleSelectionChange(selection, row) {
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row);
      this.selection = {};
      this.selection = row;
    },
    handleSelectAll(selection) {
      this.$refs.multipleTable.clearSelection();
      this.selection = {};
    },
  },
  computed: {
    computedBaseURl() {
      return this.$store.state.baseURL;
    },
    computedCost() {
      return (row) => {
        return (row.risk_premiuml / 1).toFixed(2);
      };
    },
    computedTotal() {
      return (row) => {
        return (Number(row.risk_premiuml) - Number(row.diff)).toFixed(2);
      };
    },
    computedRate() {
      return (row) => {
        return Number(row.rate) == 0 ? 0 : Number(row.rate) * 100 + "%";
      };
    },
    computedName() {
      return (name) => {
        if (name.toString().indexOf("元") >= 0) {
          return name.toString().replace("元", "") / 10000 + " 万元";
        } else {
          return name / 10000 + " 万元";
        }
      };
    },
    computedHeaders() {
      return {
        Authorization: `Bearer ${this.$store.state.token}`,
      };
    },
    computedAddress() {
      return (address) => {
        return address.replace(/\,/g, "-");
      };
    },
  },
  watch: {
    "formData.peopleNum": {
      handler: function(n, o) {
        if (n) {
          var program_code_list = [];
          var program_value_code_list = [];

          for (var j = 0; j < this.rule_list.length; j++) {
            var arr = this.rule_list[j].rule_value.split(",");
            var flag1 = false;
            var flag2 = false;
            for (var i = 0; i < arr.length; i++) {
              if (arr[i][0] == 5) {
                if (
                  this.$store.state.industry.split(",")[
                    this.$store.state.industry.split(",").length - 1
                  ] == arr[i].split("$")[1]
                ) {
                  flag1 = true;
                }
              } else if (arr[i][0] == 2 || arr[i][0] == 6) {
                var arr2 = arr[i].split("$");
                if (
                  n > (arr2[1] == "max" ? Infinity : arr2[1]) &&
                  n <= (arr2[3] == "max" ? Infinity : arr2[3])
                ) {
                  flag2 = true;
                }
              }

              if (arr.length > 1) {
                if (flag1 && flag2) {
                  program_code_list = this.rule_list[j].program_code_list;
                }
              } else {
                if (flag1 || flag2) {
                  program_code_list = this.rule_list[j].program_code_list;
                }
              }
            }
          }

          for (var k = 0; k < program_code_list.length; k++) {
            for (var l = 0; l < this.program_list.length; l++) {
              if (program_code_list[k] == this.program_list[l].prog_code) {
                program_value_code_list.push({
                  code: program_code_list[k], //FA9828
                  name: this.program_list[l].liability_list[0].prog_spl_list[0]
                    .value, //3000000
                });
              }
            }
          }

          //
          this.dutyLimitOptions = program_value_code_list;

          if (n != o) {
            this.formData.dutyLimit = "";
            this.formData.additionalRisk1Limit = "";
            this.formData.additionalRisk2Limit = "";
            this.formData.additionalRisk3Limit = "";
          }
        } else {
          this.formData.dutyLimit = "";
          this.dutyLimitOptions = [];
          this.formData.additionalRisk1Limit = "";
          this.formData.additionalRisk2Limit = "";
          this.formData.additionalRisk3Limit = "";
          this.additionalRisk1LimitOptions = [];
          this.additionalRisk2LimitOptions = [];
          this.additionalRisk3LimitOptions = [];
        }
      },
      deep: true,
      immediate: true,
    },
    formData: {
      handler(n, o) {
        if (this.steps) {
          if (
            n.factor19YZ336205 ||
            n.start_date ||
            n.dutyLimit ||
            n.personDutyLimit ||
            n.additionalRisk1Limit ||
            n.additionalRisk1Factor ||
            n.additionalRisk2Limit ||
            n.additionalRisk2Factor ||
            n.additionalRisk3Limit ||
            n.additionalRisk3Factor ||
            n.reparationFactor ||
            n.dangerSourceFactor ||
            n.manageLevelFactor ||
            n.exampleFactor ||
            n.factor19YZ261411 ||
            n.factor19YZ573684
          ) {
            this.countFactor();
          }
        }
      },
      deep: true,
    },
    checked(n, o) {
      //同投保
      if (n) {
        for (
          var i = 0;
          i < this.applicantInfo.paperwork_info_list.length;
          i++
        ) {
          if (this.applicantInfo.paperwork_info_list[i].type == "bl") {
            this.fileList = [
              {
                name: this.applicantInfo.paperwork_info_list[i].name,
                url: this.applicantInfo.paperwork_info_list[i].file_url,
              },
            ];

            this.uploadFile = {
              file_type: this.applicantInfo.paperwork_info_list[i].type,
              file_name: this.applicantInfo.paperwork_info_list[i].name,
              value: "",
              file_code: this.applicantInfo.paperwork_info_list[i].code,
            };

            this.formData.file_code = this.applicantInfo.paperwork_info_list[
              i
            ].code;
          }
        }

        //holder 值 给 insured
        this.formData.name = this.applicantInfo.com_name;
        this.formData.mobile = this.applicantInfo.link_phone;

        this.formData.address2 = this.applicantInfo.address;
        this.formData.range = this.applicantInfo.business_scope;
        this.formData.code = this.applicantInfo.idcard;

        //上传文件
        //fill back src
        this.ImageUrl = this.applicantInfo.imgSrc;

        //省市区
        this.formData.address1 = [
          this.applicantInfo.province.toString(),
          this.applicantInfo.city.toString(),
          this.applicantInfo.area.toString(),
        ];
        this.insuredAddress = {
          path: `${this.applicantInfo.province.toString()},${this.applicantInfo.city.toString()},${this.applicantInfo.area.toString()}`,
          pathLabels: this.applicantInfo.p_c_a_ch.replace(/\-/g, ","),
        };
        //放入4

        this.formData.business_nature = this.applicantInfo.unit_nature.split(
          ","
        ); //经营性质 单位性质
        this.formData.link_name = this.applicantInfo.link_name; //被保企业联系人 (姓名)
        this.formData.mobile = this.applicantInfo.link_phone; //被保企业联系方式 (电话)

        this.formData.link_p_c_a_code = [
          //被保企业联系地址 1
          this.applicantInfo.link_province.toString(),
          this.applicantInfo.link_city.toString(),
          this.applicantInfo.link_area.toString(),
        ];

        this.formData.link_address = this.applicantInfo.link_address; //被保企业联系地址 2

        this.isLock = true;
        this.getRenewData();
      } else {
        this.renewTableData = [];
        this.isRenewInsurance = false;
        this.formData.business_nature = [];
        this.formData.link_p_c_a_code = [];
        this.formData.link_name = "";
        this.formData.mobile = "";
        this.formData.link_address = "";
        this.fileList = [];
        this.uploadFile = {};

        this.formData.file_code = "";

        //insured值恢复默认
        this.ImageUrl = "";
        this.formData.name = "";
        this.formData.mobile = "";
        this.formData.address1 = [];
        this.insuredAddress = {
          path: "",
          pathLabels: "",
        };
        this.formData.address2 = "";
        this.formData.range = "";
        this.formData.code = "";
        this.isLock = false;
      }
    },
    additionalRisk1: {
      handler(n, o) {
        if (!n) {
          this.formData.additionalRisk1Limit = "";
          this.formData.additionalRisk1Factor = "";
        }
      },
      deep: true,
      immediate: true,
    },
    additionalRisk2: {
      handler(n, o) {
        if (!n) {
          this.formData.additionalRisk2Limit = "";
          this.formData.additionalRisk2Factor = "";
        }
      },
      deep: true,
      immediate: true,
    },
    additionalRisk3: {
      handler(n, o) {
        if (!n) {
          this.formData.additionalRisk3Limit = "";
          this.formData.additionalRisk3Factor = "";
        }
      },
      deep: true,
      immediate: true,
    },
    "formData.factor19YZ336205": {
      handler: function(n, o) {
        if (n) {
          if (this.formData.start_date) {
            this.formData.end_date =
              this.$moment(this.formData.start_date)
                .add(n, "months")
                .subtract(1, "seconds")
                .format("YYYY-MM-DD") + " 23:59:59";
          } else {
            this.formData.end_date = "";
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "formData.start_date": {
      handler(n, o) {
        if (!n) {
          this.formData.end_date = "";
        }
      },
      deep: true,
      immediate: true,
    },
    isShow: {
      handler(n, o) {
        if (n) {
          /* 投保人信息 */
          this.insureInfo.holder = {
            companyName: this.applicantInfo.com_name, //投保企业名
            field: this.applicantInfo.industry_value, //行业领域
            code: this.applicantInfo.idcard, //统一社会信用代码
            companyAddress:
              this.applicantInfo.p_c_a_ch + " " + this.applicantInfo.address, //企业地址
            type: this.applicantInfo.unit_nature_value, // 客户类型
            companyCharactor: this.applicantInfo.unit_nature_value, //单位性质
            name: this.applicantInfo.link_name, //  联系人姓名
            phone: this.applicantInfo.link_phone, //联系方式
            address:
              this.applicantInfo.link_p_c_a_ch +
              " " +
              this.applicantInfo.link_address, //联系人地址
            isSocial: this.formData.is_social_security, //社会工伤保险
          };

          /* 被保人信息 */
          this.insureInfo.insurer = {
            companyName: this.formData.name, //被保企业名称: this.formData.formData.name
            phone: this.formData.mobile, //被保人联系方式: formData.phoneNum
            address:
              this.insuredAddress.pathLabels + " " + this.formData.address2, //*被保企业地址:  this.insuredAddress
            field: this.formData.range, //主要经营范围:
            code: this.formData.code, //社会信用代码:
          };

          /* 产品方案 */
          this.insureInfo.case.staffNumber = this.formData.peopleNum; //从业人数：
          this.insureInfo.case.monthNumber = this.formData.factor19YZ336205; //月数（生产、批发、零售经营者）：
          this.insureInfo.case.duringTime =
            this.$moment(this.formData.start_date).format("YYYY-MM-DD") +
            " 00:00:00 至 " +
            this.formData.end_date; //保险时间：
          this.insureInfo.case.companyRiskFactor = this.companyRiskFactorList.industry_value; //企业风险系数：

          var code = "";
          if (this.formData.dutyLimit) {
            for (var i = 0; i < this.dutyLimitOptions.length; i++) {
              if (this.dutyLimitOptions[i].code == this.formData.dutyLimit) {
                code = this.dutyLimitOptions[i].name;
              }
            }
          }

          /* 基本险 */
          this.insureInfo.case.basicInsure.limit = code / 10000 + "万元"; //每次及累计责任限额:
          this.insureInfo.case.basicInsure.factor = this.selectedPersonDutyLimitItem.name; //每人责任限额调整因子（广西）:

          /* 附加险 */

          /* 附加补充雇主责任险： */

          if (this.additionalRisk1) {
            for (var i = 0; i < this.additionalRisk1LimitOptions.length; i++) {
              if (
                this.additionalRisk1LimitOptions[i].code ==
                this.formData.additionalRisk1Limit
              ) {
                this.insureInfo.case.additonInsure.additon1.limit = this.additionalRisk1LimitOptions[
                  i
                ].name;
              }
            }

            for (
              var i = 0;
              i < this.additionalRisk1FactorOptions.coefficient.length;
              i++
            ) {
              if (
                this.additionalRisk1FactorOptions.coefficient[i].code ==
                this.formData.additionalRisk1Factor
              ) {
                this.insureInfo.case.additonInsure.additon1.factor = this.additionalRisk1FactorOptions.coefficient[
                  i
                ].name;
              }
            }
          }

          /* 附加燃放责任保险 */
          if (this.additionalRisk2) {
            for (var i = 0; i < this.additionalRisk2LimitOptions.length; i++) {
              if (
                this.additionalRisk2LimitOptions[i].code ==
                this.formData.additionalRisk2Limit
              ) {
                this.insureInfo.case.additonInsure.additon2.limit = this.additionalRisk2LimitOptions[
                  i
                ].name;
              }
            }

            for (
              var i = 0;
              i < this.additionalRisk2FactorOptions.coefficient.length;
              i++
            ) {
              if (
                this.additionalRisk2FactorOptions.coefficient[i].code ==
                this.formData.additionalRisk2Factor
              ) {
                this.insureInfo.case.additonInsure.additon2.factor = this.additionalRisk2FactorOptions.coefficient[
                  i
                ].name;
              }
            }
          }
          /* 附加补充公众责任险 */
          if (this.additionalRisk3) {
            for (var i = 0; i < this.additionalRisk3LimitOptions.length; i++) {
              if (
                this.additionalRisk3LimitOptions[i].code ==
                this.formData.additionalRisk3Limit
              ) {
                this.insureInfo.case.additonInsure.additon3.limit = this.additionalRisk3LimitOptions[
                  i
                ].name;
              }
            }

            for (
              var i = 0;
              i < this.additionalRisk3FactorOptions.coefficient.length;
              i++
            ) {
              if (
                this.additionalRisk3FactorOptions.coefficient[i].code ==
                this.formData.additionalRisk3Factor
              ) {
                this.insureInfo.case.additonInsure.additon3.factor = this.additionalRisk3FactorOptions.coefficient[
                  i
                ].name;
              }
            }
          }

          /* 因子 */
          this.factor = [
            {
              //以往赔偿记录调整因子
              name: "以往赔偿记录调整因子",
              factor: this.formData.reparationFactor,
              options: this.reparationFactorOptions.coefficient,
            },
            {
              //重大危险源调整因子
              name: "重大危险源调整因子",
              factor: this.formData.dangerSourceFactor,
              options: this.dangerSourceFactorOptions.coefficient,
            },
            {
              //安全管理水平调整因子(煤矿)
              name: "安全管理水平调整因子(煤矿)",
              factor: this.formData.manageLevelFactor,
              options: this.manageLevelFactorOptions.coefficient,
            },
            {
              //安全文化建设示范企业因子
              name: "安全文化建设示范企业因子",
              factor: this.formData.exampleFactor,
              options: this.exampleFactorOptions.coefficient,
            },
            {
              name: "企业安全生产标准化因子",
              factor: this.formData.factor19YZ261411,
              options: this.factor19YZ261411Options.coefficient,
            },
            {
              name: "季节性零售月数",
              factor: this.formData.factor19YZ573684,
              options: this.factor19YZ573684Options.coefficient,
            },
          ];

          //是否投保单审核
          this.insureInfo.case.isCheck =
            this.formData.policy_audit == 1 ? "审核" : "不审核";

          //选择保单：email address
          this.insureInfo.case.selectInsure = this.formData.mail_type; //== 1 ? "电子保单" : "纸质保单"

          this.insureInfo.case.mailAdress = this.formData.docAddress;
          this.insureInfo.case.address =
            this.mailAddress.pathLabels + " " + this.formData.docAddress2;
          this.insureInfo.case.isCheck =
            this.formData.policy_audit == 1 ? "是" : "否";
          //保单试算
          this.insureInfo.case.risk_premiums = this.tableData;
          this.insureInfo.case.formula = this.premium_memo;

          //获取保险产品的保险额度
          /* this.axios({
            method: "post",
            url: "/api/v1/prod/getProductRisk",
            data: {
              product_code: this.$route.query.product_code, //保险产品
              industry_code: this.$store.state.industry, //行业编码
              risk: [
       
              ],
            },
          }).then((res) => {
            if (res.data.code == 0) {
            }
         
          }); */
        }
      },
      immediate: true,
    },
    radioOrderNo: {
      handler(n, o) {
        if (n) {
          this.reparationFactorOptions.coefficient = [];
          this.formData.reparationFactor = "";
        }
      },
    },
  },
};
</script>

<style scoped>
.box-card {
  width: 1200px;
  margin: 20px auto;
  text-align: left;
}

.box-card h3 {
  padding-left: 10px;
  border-left: 5px solid #67c23a;
}

.item {
  width: 800px;
  margin: 0 auto;
}

.header-icon {
  margin-left: 20px;
}

.termTitle1:hover .header-icon {
  color: #e6a23c;
}
.item1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.item1 dd {
  margin-left: 5px;
  margin-right: 20px;
  width: 300px;
  line-height: 30px;
}
.item1 dt {
  color: #999;
  width: 200px;
  text-align: right;
}
.upload-demo {
  margin-top: -41px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
  border: 1px #dcdfe6 solid;
  margin-right: 20px;
}
.el-icon-plus {
  border: 1px #dcdfe6 solid;
  margin-right: 20px;
}
.selectWidth {
  width: 400px;
}

.comfirmDialog .el-dialog__body {
  padding-top: 0px;
}
.comfirmDialog .head {
  margin: 20px 0px -18px 0px;
  display: flex;
  color: #409eff;
  border-left: 3px solid #409eff;
  padding-left: 8px;
  font-weight: bold;
}
.comfirmDialog .item,
.comfirmDialog .item1 {
  display: flex;
  flex-wrap: wrap;
  color: #909399;
  width: 100%;
  text-align: left;
}
.comfirmDialog .item2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.comfirmDialog .title {
  font-weight: 600;
  line-height: 30px;
}
.comfirmDialog .textcolor {
  color: black;
  padding-left: 5px;
  line-height: 30px;
}
.comfirmDialog .content1 > h4 {
  text-align: left;
}
.comfirmDialog .width50 {
  width: 50%;
}
</style>
