<template>
  <div>
    <Card1 :hasBtn="hasBtn" />
    <el-card class="box-card">
      <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
        <el-tab-pane label="产品方案" name="1">
          <ProductScheme :product_code="product_code" />
        </el-tab-pane>
        <el-tab-pane label="投保须知" name="2">
          <InsureNotice :product_code="product_code" />
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import Card1 from "@/components/product/Card1.vue";
import InsureNotice from "@/components/product/detail/InsureNotice.vue";
import ProductScheme from "@/components/product/detail/ProductScheme.vue";
export default {
  components: {
    Card1,
    InsureNotice,
    ProductScheme,
  },
  mounted() {
    this.hasBtn = this.$route.query.industry_code;
    this.product_code = this.$route.query.product_code;
  },
  data() {
    return {
      activeName: "1",
      hasBtn: "",
      product_code:''
    };
  },
  methods: {
    handleClick(tab) {},
  },
};
</script>

<style scoped>
.box-card {
  width: 1200px;
  margin: 20px auto;
}
</style>