<template>
  <div>
    <Steps :stepNum="1" />
    <Card1 :hasBtn="0" />
    <Card2 />
  </div>
</template>

<script>
import Steps from "@/components/product/Steps.vue";
import Card1 from "@/components/product/Card1.vue";
import Card2 from "@/components/product/Card2.vue";
export default {
  components: {
    Steps,
    Card1,
    Card2,
  },
};
</script>

<style scoped>
</style>