<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <h3>投保单确认</h3>
      </div>
      <el-table
        :data="tableData1"
        style="width: 100%"
        :header-cell-style="headerRowStyle"
      >
        <el-table-column prop="proposal_no" label="投保单号" width="260">
        </el-table-column>
        <el-table-column prop="company" label="出单公司"> </el-table-column>
        <el-table-column label="保单类型" width="100">
          <span slot-scope="scope">
            {{ computedpolicyType(scope.row) }}
          </span>
        </el-table-column>
        <el-table-column label="保单期间" width="320">
          <span slot-scope="scope">
            {{ computedDate(scope.row) }}
          </span>
        </el-table-column>
      </el-table>

      <el-table
        :data="tableData2"
        style="width: 100%; margin-top: 20px"
        :header-cell-style="headerRowStyle"
        :cell-style="cellStyle"
      >
        <el-table-column label="险种" prop="type"> </el-table-column>
        <el-table-column prop="formulas_detail" label="计算公式">
        </el-table-column>
        <el-table-column label="保费金额（元）" width="200" prop="before_prem">
        </el-table-column>
        <el-table-column label="优惠比例" width="200" prop="ratio">
          <span slot-scope="scope">
            {{ computedRatio(scope.row) }}
          </span>
        </el-table-column>
        <el-table-column prop="after_prem" label="实收金额（元）" width="200">
        </el-table-column>

        <div
          slot="append"
          style="
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 50px;
            padding-left: 8px;
            padding-right: 120px;
            background-color: #f5f7fa;
          "
        >
          <div class="sum_footer_unit">
            保费合计：
            <span style="color: red">¥ {{ sum }} 元</span>
          </div>
        </div>
      </el-table>

      <el-form
        :model="FormData"
        :rules="rules"
        ref="ruleForm"
        label-width="10px"
        class="demo-ruleForm"
      >
        <el-form-item label=" " style="margin-bottom: 0">
          <el-radio :label="1" v-model="FormData.radio">转账支付</el-radio>
        </el-form-item>

        <el-form-item label=" " prop="checked">
          <el-checkbox v-model="FormData.checked" :label="1"
            >我已阅读保险条款的全部内容，充分理解并接受包括免除保险公司责任条款、犹豫期、费用扣除、退保、保险单现金价值等在内的重要事项。</el-checkbox
          >
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            :loading="isSubmitLoading"
            >提交投保单</el-button
          >
        </el-form-item>
        <h4 style="margin-left: 10px; margin-bottom: 1px">投保提示：</h4>
        <p style="width: 900px; margin-left: 10px; margin-top: 4px">
          请您填写投保单前详细阅读保险条款，特别注意条款中的保险责任、责任免除、投保人被保险人义务、赔偿处理等内容。
          为保障您的合法权益，请您如实，完整。准确地填写本投保单(请在选择项目的“口”内打“√”）。投保信息如有变动请及时办理变更手续。
          请您按本投保单的约定及时交纳保险费
        </p>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSubmitLoading: false,
      FormData: {
        checked: [1],
        radio: 1,
      },
      rules: {
        checked: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个",
            trigger: "change",
          },
        ],
      },
      tableData1: [
        {
          proposal_no: "",
          order_no: "",
          company: "",
        },
      ],
      tableData2: [
        {
          type: "",
          formulas_detail: "",
          after_prem: "",
          ratio: 0,
        },
      ],
      sum: 0,
    };
  },
  mounted() {},
  methods: {
    getSummaries(param) {
      var num = 0;
      for (var i = 0; i < param.data.length; i++) {
        num += param.data[i].calculate;
      }

      this.sum = num;
    },
    submitForm() {
      if (this.$store.state.submitObj.order_no) {
        this.isSubmitLoading = true;
        this.axios({
          url: "/api/v1/order/sureOrder",
          method: "post",
          data: {
            order_no: this.tableData1[0].order_no,
          },
        }).then((res) => {
          if (res.data.code > 0) {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          } else {
            this.$router.push({
              path: "/product/success",
            });
          }
          this.isSubmitLoading = false;
        });
      } else {
        this.$router.push({
          path: "/",
        });
      }
    },
    headerRowStyle(obj) {
      return {
        color: "#67C23A",
        background: "#f0f9eb",
      };
    },
    cellStyle(obj) {},
  },
  computed: {
    computedRatio() {
      return (row) => {
      
        return   Number(row.ratio)==0?0:Number(row.ratio)*100+'%';
      };
    },
    computedSum() {
      return this.tableData2[0].after_prem;
    },
    computedpolicyType() {
      return (row) => {
        if (row.policy_type == 1) {
          return "电子保单";
        } else if (row.policy_type == 2) {
          return "纸质保单";
        }
      };
    },
    computedDate() {
      return (row) => {
        return row.start_time + "至" + row.end_time;
      };
    },
  },
  watch: {
    "$store.state.submitObj": {
      handler(n, o) {
        console.log(n, "get table 2 data");
        this.tableData1 = [
          {
            order_no: n.order_no,
            proposal_no: n.proposal_no,
            company: n["insurance company"],
            policy_type: 1,
            end_time: n.end_time,
            start_time: n.start_time,
          },
        ];
        this.tableData2 = [
          {
            type: "安责险保费",
            formulas_detail: n.premium.formulas_detail,
            after_prem: n.after_prem,
            before_prem: n.before_prem,
            ratio: n.discount_rate,
          },
        ];
        this.sum = n.after_prem;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.box-card {
  text-align: left;
  width: 1200px;
  margin: 0 auto 20px;
}
.box-card h3 {
  padding-left: 10px;
  border-left: 5px solid #67c23a;
}
.demo-ruleForm {
  margin-top: 20px;
}
</style>
