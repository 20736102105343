<template>
  <div>
    <el-card class="box-card" shadow="hover">
      <div slot="header" class="">
        <h3>请您阅读以下内容：</h3>
        <p style="color: red">
          为了维护您的合法权益，根据《中华人民共和国保险法》、《中华人民共和国民法通则》等法律法规要求。
          请您知晓以下事项：
        </p>
      </div>
      <div class="item">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="10px"
          class="demo-ruleForm"
          :rules="rules"
        >
          <el-form-item
            :prop="key"
            label=" "
            v-for="(value, key, i) in ruleForm"
            :key="i"
          >
            <span slot="label" style="color: red">*</span>
            <el-checkbox v-model="ruleForm[key].checked"
              >我已认真阅读
              <el-link
                type="primary"
                :href="
                  computedBaseURl +
                  '/api/v1/resources/download?file_code=' +
                  ruleForm[key].downLoad
                "
                :download="ruleForm[key].title"
                >《{{ ruleForm[key].title }}》 </el-link
              >，充分理解并接受相关内容</el-checkbox
            >
          </el-form-item>
          <el-form-item style="text-align: center">
            <el-button type="primary" @click="submitForm('ruleForm')"
              >同意</el-button
            >
            <el-button @click="toLink()">不同意</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>


<script>
export default {
  data() {
    var validate1 = (rule, value, callback) => {
      if (this.ruleForm.name1.checked) {
        callback();
      } else {
        return callback(new Error("不能为空"));
      }
    };
    var validate2 = (rule, value, callback) => {
      if (this.ruleForm.name2.checked) {
        callback();
      } else {
        return callback(new Error("不能为空"));
      }
    };
    var validate3 = (rule, value, callback) => {
      if (this.ruleForm.name3.checked) {
        callback();
      } else {
        return callback(new Error("不能为空"));
      }
    };
    var validate4 = (rule, value, callback) => {
      if (this.ruleForm.name4.checked) {
        callback();
      } else {
        return callback(new Error("不能为空"));
      }
    };
    var validate5 = (rule, value, callback) => {
      if (this.ruleForm.name5.checked) {
        callback();
      } else {
        return callback(new Error("不能为空"));
      }
    };
    var validate6 = (rule, value, callback) => {
      if (this.ruleForm.name6.checked) {
        callback();
      } else {
        return callback(new Error("不能为空"));
      }
    };
    var validate7 = (rule, value, callback) => {
      if (this.ruleForm.name7.checked) {
        callback();
      } else {
        return callback(new Error("不能为空"));
      }
    };
    var validate8 = (rule, value, callback) => {
      if (this.ruleForm.name8.checked) {
        callback();
      } else {
        return callback(new Error("不能为空"));
      }
    };
    return {
      ruleForm: {},
      rules: {},
    };
  },
  mounted() {
    this.getListData();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$router.push({
            path: "/product/insure",
            query: { product_code: this.$route.query.product_code },
          });
        }
      });
    },
    toLink() {
      this.$router.push("/product");
    },
    getListData() {
      var product_code = this.$route.query.product_code;
      if (product_code) {
        this.axios({
          url: "/api/v1/prod/notificationInfo",
          method: "post",
          data: {
            product_code: product_code,
          },
        }).then((res) => {
          var arr = res.data.result;
          var obj = {};
          var rulesObj = {};
          for (var i = 0; i < arr.length; i++) {
            obj["name" + arr[i].order] = {
              title: arr[i].name,
              downLoad: arr[i].value,
              checked: false,
            };
            rulesObj["name" + arr[i].order] = [
              {
                validator: function (rule, value, callback) {
                  if (value.checked) {
                    callback();
                  } else {
                    return callback(new Error("不能为空"));
                  }
                },
                trigger: "change",
              },
            ];
          }
          this.ruleForm = obj;
          this.rules = rulesObj;
        });
      }
    },
  },
  computed: {
    computedBaseURl() {
      return this.$store.state.baseURL;
    },
  },
};
</script>

<style scoped>
.box-card {
  text-align: left;
}
.item {
  padding: 18px 0;
}

.box-card {
  width: 1200px;
  margin: 20px auto;
}
</style>