<template>
  <div style="text-align: left; width: 900px; margin: 0 auto">
    <p  style="border: 1px solid #333; boxing-size: border; padding: 20px">
      <span style="font-weight: 700;">提示：为保障您的权益，</span>请详细阅读网上投保注意事项、广西安全生产责任保险统保项目产品保险条款、广西安全生产责任统保项目保险经纪服务委托协议、中惠保险经纪有限公司告知事项。
    </p>
    <h3>网上投保注意事项</h3>
    <p>
      1.请您填写投保信息前详细阅读保险条款，特别注意条款中的保险责任、责任免除、投保人被保险人义务、赔偿处理等内容。
    </p>
    <p>
      2.为保障您的合法权益，请您如实、完整、准确地填写投保信息。投保信息如有变动请及时办理变更手续。
    </p>
    <div style="border: 1px solid #333; boxing-size: border; padding: 0 20px">
      <p>
        依据：《中华人民共和国保险法（2009修订）》第十六条订立保险合同，保险人就保险标的或者被保险人的有关情况提出询问的，投保人应当如实告知。
      </p>
      <p>
        投保人故意或者因重大过失未履行前款规定的如实告知义务，足以影响保险人决定是否同意承保或者提高保险费率的，保险人有权解除合同。
      </p>
      <p>
        投保人故意不履行如实告知义务的，保险人对于合同解除前发生的保险事故，不承担赔偿或者给付保险金的责任，并不退还保险费。
      </p>
      <p>
        投保人因重大过失未履行如实告知义务，对保险事故的发生有严重影响的，保险人对于合同解除前发生的保险事故，不承担赔偿或者给付保险金的责任，但应当退还保险费。
      </p>
      <p>保险事故是指保险合同约定的保险责任范围内的事故。</p>
      <p>《中华人民共和国保险法》</p>
    </div>
    <p>3.投保信息将成为保险人承保的依据，并作为保险合同的一部分。</p>
    <p>4.请您严格按照投保约定缴纳保险费。</p>
    <p>
      5.为保证保险人在拟定的保险起期开始承担保险责任，<strong>请您在保险起期前5个工作日办理投保手续、提交投保资料并完成保费的支付；保险费到达广西安全生产责任保险统保项目保险费专收账户隔日（即第三日）零时，保险公司签发正式保单。如您未能按时办理投保手续或保险费未能按时到账，导致保险合同未在拟定的保险起期生效，发生保险事故，保险人不承担保险责任。</strong>
    </p>
    <p>
      6.广西（南宁市、百色市、河池市、崇左市、北海市、钦州市、防城港市七个市）安全生产责任保险统保项目由三家保险公司共保，中国人民财产保险股份有限公司广西壮族自治区分公司首席承保人，承保份额为68%；中国太平洋财产保险股份有限公司，承保份额为22%；中国大地财产保险股份有限公司，承保份额为10%。本区域保险的出单公司为
      中国人民财产保险股份有限公司广西壮族自治区分公司;
    </p>
    <p>
      7.本投保人承诺：本投保单填报从业人员数为与被保险人签订劳动合同或存在劳动关系的全部人员总数。（其中从业人员是指与被保险人签订劳动合同或存在劳动关系（包括事实劳动关系）的各种用工形式、各种用工期限，接受被保险人给付的劳动报酬，且符合国家劳动法规定的合法劳动者，也包括退休返聘的人员、劳务派遣人员、兼职人员、短期工、临时工、季节工、徒工、实习生以及与被保险人签订劳动合同或存在劳动关系（包括事实劳动关系）的董事、监事及高级管理人员）。
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>