<template>
  <div>
    <el-card class="box-card" shadow="hover">
      <div class="item">
        <img
          v-if="productInfo.product_code"
          :src="require(`../../assets/images/${productInfo.product_code}.png`)"
          alt=""
          width="447px"
          height="337px"
        />
        <div style="margin-left: 20px">
          <h4 style="font-size: 30px; margin-top: 0">
            {{ productInfo.title }}
          </h4>
          <dl class="listwrap">
            <dt>保障范围:</dt>
            <dd>{{ productInfo.insurance_scope }}</dd>
            <dt>保险责任:</dt>
            <dd>{{ productInfo.insurance_duty }}</dd>
            <dt>承保公司:</dt>
            <dd style="display: flex">
              <div
                v-for="(item, i) in computedInusureCompany(
                  productInfo.inusure_company
                )"
                :key="i"
              >
                <img
                  v-if="productInfo.inusure_company"
                  :src="require('../../assets/images/icon/' + item + '.jpg')"
                  alt=""
                  style="border: 1px #efefef solid; margin-right: 8px"
                />
              </div>
            </dd>
            <dt>适用企业:</dt>
            <dd>{{ productInfo.for_enterprise }}</dd>
            <dt>保险特色:</dt>
            <dd>{{ productInfo.insurance_eatures }}</dd>
            <dt>销售区域:</dt>
            <dd>{{ productInfo.sales_area }}</dd>
            <dt>保单形式:</dt>
            <dd>{{ productInfo.insurance_polic_type }}</dd>
            <dt>保障期限:</dt>
            <dd>{{ productInfo.insurance_term }}</dd>
            <dt>最高保额:</dt>
            <dd>
              <span style="color: red; font-size: 20px; margin: 0 5px">{{
                productInfo.coverage_limits
              }}</span>
            </dd>
          </dl>
          <el-button type="primary" v-if="hasBtn != ''" @click="jumpto"
            >立即投保</el-button
          >
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: ["hasBtn"],
  data() {
    return {
      productInfo: {},
    };
  },
  mounted() {
    if (this.$route.query.product_code) {
      this.getData();
    }
  },
  methods: {
    jumpto() {
      this.$router.push({
        path: "/product/clause",
        query: { product_code: this.$route.query.product_code },
      });
    },
    getData() {
      if (this.$store.state.userCode) {
        this.axios({
          url: "/api/v1/user/userDetails",
          method: "post",
          data: {
            user_code: this.$store.state.userCode,
          },
        }).then((res) => {
          if (res) {
            if (res.data.code > 0) {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 1000);
            } else {
              var industry = res.data.result.industry;

              this.$store.dispatch("industry", industry).then(() => {});
              this.axios
                .get(`/api/v1/prod/prodList?industry_code=${industry}`)
                .then((res) => {
                  if (res.data.code > 0) {
                    this.$message({
                      message: res.data.msg,
                      type: "error",
                    });
                    setTimeout(() => {
                      this.$router.push("/");
                    }, 1000);
                  } else {
                    var result = res.data.result;
                    for (var i = 0; i < result.length; i++) {
                      if (
                        result[i].product_code == this.$route.query.product_code
                      ) {
                        this.productInfo = result[i];
                      
                      }
                    }
                  }
                });
            }
          }
        });
      } else {
        this.axios.get("/api/v1/prod/prodList?industry=").then((res) => {
          if (res.data.code > 0) {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
            setTimeout(() => {
              this.$router.push("/");
            }, 1000);
          } else {
            var result = res.data.result;
            for (var i = 0; i < result.length; i++) {
              if (result[i].product_code == this.$route.query.product_code) {
                this.productInfo = result[i];
              }
            }
          }
        });
      }
    },
  },
  computed: {
    computedInusureCompany() {
      return (str) => {
        if (str) {
          var arr = str.split(",");
          return arr;
        }
      };
    },
  },
};
</script>

<style scoped>
.item {
  /*  padding: 18px 0; */
  display: flex;
}
.item > img {
  border-radius: 12px;
}

.box-card {
  margin-top: 20px;
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}
.listwrap {
  display: flex;
  flex-wrap: wrap;
}
.listwrap dt {
  width: 72px;
  line-height: 24px;
}
.listwrap dd {
  width: 570px;
  line-height: 24px;
  margin-bottom: 10px;
  margin-left: 0;
}
</style>