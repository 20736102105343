<template>
  <div>
    <!-- 危化行业 -->
    <div
      style="width: 900px; margin: 0 auto"
      v-if="product_code == 'PR6430' || product_code == 'PR9274'"
    >
      <p
        style="
          padding-left: 20px;
          border-left: 4px solid #67c23a;
          text-align: left;
          color: #67c23a;
        "
      >
        生产企业基础保险费
      </p>
      <el-table :data="productSchemeList1" style="width: 100%">
        <el-table-column type="index" width="50" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="employeeNum" label="从业人员人数">
        </el-table-column>
        <el-table-column prop="insureLimitGrade" label="最低投保限额档次">
        </el-table-column>
        <el-table-column prop="dutyLimitTotal" label="累计责任限额">
        </el-table-column>
      </el-table>

      <p
        style="
          padding-left: 20px;
          border-left: 4px solid #67c23a;
          text-align: left;
          color: #67c23a;
        "
      >
        经营企业基础保险费
      </p>
      <el-table :data="productSchemeList2" style="width: 100%">
        <el-table-column type="index" width="50" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="employeeNum" label="从业人员人数">
        </el-table-column>
        <el-table-column prop="insureLimitGrade" label="最低投保限额档次">
        </el-table-column>
        <el-table-column prop="dutyLimitTotal" label="累计责任限额">
        </el-table-column>
      </el-table>
    </div>
    <!-- 烟花爆竹行业 -->
    <div
      style="width: 900px; margin: 0 auto"
      v-if="
        product_code == 'PR0830' ||
        product_code == 'PR7679' ||
        product_code == 'PR2311'
      "
    >
      <p
        style="
          padding-left: 20px;
          border-left: 4px solid #67c23a;
          text-align: left;
          color: #67c23a;
        "
      >
        生产企业
      </p>
      <el-table :data="productSchemeList1" style="width: 100%">
        <el-table-column type="index" width="50" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="employeeNum" label="从业人员人数">
        </el-table-column>
        <el-table-column prop="insureLimitGrade" label="最低投保限额档次">
        </el-table-column>
        <el-table-column prop="dutyLimitTotal" label="累计责任限额">
        </el-table-column>
      </el-table>

      <p
        style="
          padding-left: 20px;
          border-left: 4px solid #67c23a;
          text-align: left;
          color: #67c23a;
        "
      >
        批发企业
      </p>
      <el-table :data="productSchemeList2" style="width: 100%">
        <el-table-column type="index" width="50" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="employeeNum" label="从业人员人数">
        </el-table-column>
        <el-table-column prop="insureLimitGrade" label="最低投保限额档次">
        </el-table-column>
        <el-table-column prop="dutyLimitTotal" label="累计责任限额">
        </el-table-column>
      </el-table>

      <p
        style="
          padding-left: 20px;
          border-left: 4px solid #67c23a;
          text-align: left;
          color: #67c23a;
        "
      >
        长期零售经营者
      </p>
      <el-table :data="productSchemeList3" style="width: 100%">
        <el-table-column type="index" width="50" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="employeeNum" label="从业人员人数">
        </el-table-column>
        <el-table-column prop="insureLimitGrade" label="最低投保限额档次">
        </el-table-column>
        <el-table-column prop="dutyLimitTotal" label="累计责任限额">
        </el-table-column>
      </el-table>

      <p
        style="
          padding-left: 20px;
          border-left: 4px solid #67c23a;
          text-align: left;
          color: #67c23a;
        "
      >
        季节性零售经营者（一个月）
      </p>
      <el-table :data="productSchemeList4" style="width: 100%">
        <el-table-column type="index" width="50" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="employeeNum" label="从业人员人数">
        </el-table-column>
        <el-table-column prop="insureLimitGrade" label="最低投保限额档次">
        </el-table-column>
        <el-table-column prop="dutyLimitTotal" label="累计责任限额">
        </el-table-column>
      </el-table>
    </div>
    <!-- 煤炭行业 -->
    <div v-if="'PR5939' == product_code">
      <p
        style="
          padding-left: 20px;
          border-left: 4px solid #67c23a;
          text-align: left;
          color: #67c23a;
        "
      >
        煤矿行业
      </p>
      <el-table :data="productSchemeList1" style="width: 100%">
        <el-table-column type="index" width="50" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="employeeNum" label="从业人员人数">
        </el-table-column>
        <el-table-column prop="insureLimitGrade" label="最低投保限额档次">
        </el-table-column>
        <el-table-column prop="dutyLimitTotal" label="累计责任限额">
        </el-table-column>
      </el-table>
    </div>
    <!-- 金属冶炼及其他工贸行业 -->
    <div v-if="'PR0616' == product_code">
      <p
        style="
          padding-left: 20px;
          border-left: 4px solid #67c23a;
          text-align: left;
          color: #67c23a;
        "
      >
        金属冶炼行业
      </p>
      <el-table :data="productSchemeList1" style="width: 100%">
        <el-table-column type="index" width="50" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="employeeNum" label="从业人员人数">
        </el-table-column>
        <el-table-column prop="insureLimitGrade" label="最低投保限额档次">
        </el-table-column>
        <el-table-column prop="dutyLimitTotal" label="累计责任限额">
        </el-table-column>
      </el-table>
    </div>
    <!-- 非煤矿行业 -->
    <div v-if="'PR8812' == product_code">
            <p
        style="
          padding-left: 20px;
          border-left: 4px solid #67c23a;
          text-align: left;
          color: #67c23a;
        "
      >
        非煤矿行业
      </p>
      <el-table :data="productSchemeList1" style="width: 100%">
        <el-table-column type="index" width="50" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="employeeNum" label="从业人员人数">
        </el-table-column>
        <el-table-column prop="insureLimitGrade" label="最低投保限额档次">
        </el-table-column>
        <el-table-column prop="dutyLimitTotal" label="累计责任限额">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["product_code"],
  mounted() {

    this.getList(this.$route.query.product_code);
  },
  data() {
    return {
      title: "煤矿行业",
      productSchemeList1: [],
      productSchemeList2: [],
      productSchemeList3: [],
      productSchemeList4: [],
    };
  },
  methods: {
    getList(product_code) {
      if (product_code == "PR6430" || product_code == "PR9274") {
        //危化行业
     
        this.productSchemeList1 = [
          {
            employeeNum: "0＜b≤20", //从业人员人数（a）
            insureLimitGrade: "第1档", //最低投保限额档次
            dutyLimitTotal: "300万元", //累计责任限额
          },
          {
            employeeNum: "20＜b≤50",
            insureLimitGrade: "第1档",
            dutyLimitTotal: "300万元",
          },
          {
            employeeNum: "50＜b≤100",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            employeeNum: "100＜b≤200",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            employeeNum: "200＜b≤300",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            employeeNum: "300＜b≤400",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            employeeNum: "400＜b≤500",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            employeeNum: "500＜b≤600",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            employeeNum: "600＜b≤800",
            insureLimitGrade: "第3档",
            dutyLimitTotal: "1000万元",
          },
          {
            employeeNum: "800＜b≤1000",
            insureLimitGrade: "第3档",
            dutyLimitTotal: "1000万元",
          },
          {
            employeeNum: "1000＜b≤1200",
            insureLimitGrade: "第4档",
            dutyLimitTotal: "2000万元",
          },
          {
            employeeNum: "1200＜b≤1500",
            insureLimitGrade: "第4档",
            dutyLimitTotal: "2000万元",
          },
          {
            employeeNum: "1500＜b",
            insureLimitGrade: "第4档",
            dutyLimitTotal: "2000万元",
          },
          {
            employeeNum: "1500＜b",
            insureLimitGrade: "第5档",
            dutyLimitTotal: "5000万元",
          },
        ];

        this.productSchemeList2 = [
          {
            employeeNum: "0＜b≤5",
            insureLimitGrade: "第1档",
            dutyLimitTotal: "300万元",
          },
          {
            employeeNum: "5＜b≤10",
            insureLimitGrade: "第1档",
            dutyLimitTotal: "300万元",
          },
          {
            employeeNum: "10＜b≤20",
            insureLimitGrade: "第1档",
            dutyLimitTotal: "300万元",
          },
          {
            employeeNum: "20＜b≤50",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            employeeNum: "50＜b",
            insureLimitGrade: "第3档",
            dutyLimitTotal: "1000万元",
          },
        ];
      } else if (
        product_code == "PR0830" ||
        product_code == "PR7679" ||
        product_code == "PR2311"
      ) {
        //烟花爆竹行业:

        this.productSchemeList1 = [
          {
            employeeNum: "0＜b≤10", //从业人员人数（a）
            insureLimitGrade: "第1档", //最低投保限额档次
            dutyLimitTotal: "300万元", //累计责任限额
          },
          {
            employeeNum: "20＜b≤50",
            insureLimitGrade: "第1档",
            dutyLimitTotal: "300万元",
          },
          {
            employeeNum: "20＜b≤50",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            employeeNum: "50＜b≤100",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            employeeNum: "100＜b≤150",
            insureLimitGrade: "第3档",
            dutyLimitTotal: "1000万元",
          },
          {
            employeeNum: "150＜b≤200",
            insureLimitGrade: "第3档",
            dutyLimitTotal: "1000万元",
          },
          {
            employeeNum: "200＜b≤250",
            insureLimitGrade: "第3档",
            dutyLimitTotal: "1000万元",
          },
          {
            employeeNum: "250＜b≤300",
            insureLimitGrade: "第3档",
            dutyLimitTotal: "1000万元",
          },
          {
            employeeNum: "300＜b",
            insureLimitGrade: "第3档",
            dutyLimitTotal: "1000万元",
          },
        ];
        this.productSchemeList2 = [
          /* 





          */
          {
            /*           1


 */
            employeeNum: "0＜b≤5",
            insureLimitGrade: "第1档",
            dutyLimitTotal: "300万元",
          },

          {
            /* 2


 */
            employeeNum: "5＜b≤10",
            insureLimitGrade: "第1档",
            dutyLimitTotal: "300万元",
          },
          {
            /* 3


 */
            employeeNum: "10＜b≤20",
            insureLimitGrade: "第1档",
            dutyLimitTotal: "300万元",
          },
          {
            /* 4


 */
            employeeNum: "20＜b≤50",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 5

第3档
1000万元 */
            employeeNum: "50＜b",
            insureLimitGrade: "第3档",
            dutyLimitTotal: "1000万元",
          },
        ];
        this.productSchemeList3 = [
          /* 





          */
          {
            /*           1

第1档
300万元 */
            employeeNum: "0＜b≤5",
            insureLimitGrade: "第1档",
            dutyLimitTotal: "300万元",
          },
          {
            /* 2

第1档
300万元 */
            employeeNum: "5＜b≤10",
            insureLimitGrade: "第1档",
            dutyLimitTotal: "300万元",
          },
          {
            /* 3

第1档
300万元 */
            employeeNum: "10＜b≤20",
            insureLimitGrade: "第1档",
            dutyLimitTotal: "300万元",
          },
          {
            /* 4


 */
            employeeNum: "20＜b≤50",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            employeeNum: "50＜b",
            insureLimitGrade: "第3档",
            dutyLimitTotal: "1000万元",
          },
        ];
        this.productSchemeList4 = [
          /* 





          */
          {
            /*           1

第1档

2 */
            employeeNum: "0＜b≤5",
            insureLimitGrade: "第1档",
            dutyLimitTotal: "300万元",
          },
          {
            /* 
第1档
 */
            employeeNum: "5＜b≤10",
            insureLimitGrade: "第1档",
            dutyLimitTotal: "300万元",
          },
          {
            /* 3


 */
            employeeNum: "10＜b≤20",
            insureLimitGrade: "第1档",
            dutyLimitTotal: "300万元",
          },
          {
            /* 4


 */
            employeeNum: "20＜b≤50",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 5

第3档
1000万元 */
            employeeNum: "50＜b",
            insureLimitGrade: "第3档",
            dutyLimitTotal: "1000万元",
          },
        ];
      } else if ("PR5939" == product_code) {
        //煤炭行业

        this.productSchemeList1 = [
          {
            /*         1
0＜b≤20
第1档
300万元 */
            employeeNum: "0＜b≤2", //从业人员人数（a）
            insureLimitGrade: "第1档", //最低投保限额档次
            dutyLimitTotal: "300万元", //累计责任限额
          },
          {
            /* 
2
20＜b≤50
第1档
300万元 */
            employeeNum: "20＜b≤50",
            insureLimitGrade: "第1档",
            dutyLimitTotal: "300万元",
          },
          {
            /* 3
50＜b≤100
第2档
500万元 */
            employeeNum: "50＜b≤100",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 4
100＜b≤200
第2档
500万元 */
            employeeNum: "100＜b≤200",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 5
200＜b≤300
第2档
500万元 */
            employeeNum: "200＜b≤300",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 6
300＜b≤400
第2档
500万元 */
            employeeNum: "300＜b≤400",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 7
400＜b≤500
第2档
500万元 */
            employeeNum: "400＜b≤500",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 8
500＜b≤600
第2档
500万元 */
            employeeNum: "500＜b≤600",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 9
600＜b≤800
第3档
1000万元 */
            employeeNum: "600＜b≤800",
            insureLimitGrade: "第3档",
            dutyLimitTotal: "1000万元",
          },
          {
            /* 10
800＜b≤1000
第3档
1000万元 */
            employeeNum: "800＜b≤1000",
            insureLimitGrade: "第3档",
            dutyLimitTotal: "1000万元",
          },
          {
            /* 11
1000＜b≤1200
第4档
2000万元 */
            employeeNum: "1000＜b≤1200",
            insureLimitGrade: "第4档",
            dutyLimitTotal: "2000万元",
          },
          {
            /* 12
1200＜b≤1500
第4档
2000万元 */
            employeeNum: "1200＜b≤1500",
            insureLimitGrade: "第4档",
            dutyLimitTotal: "2000万元",
          },
          {
            /* 13
1500＜b
第4档
2000万元 */
            employeeNum: "1500＜b",
            insureLimitGrade: "第4档",
            dutyLimitTotal: "2000万元",
          },
          {
            /* 14
1500＜b
第5档
5000万元 */
            employeeNum: "1500＜b",
            insureLimitGrade: "第5档",
            dutyLimitTotal: "5000万元",
          },
        ];
      } else if ("PR0616" == product_code) {
        //金属冶炼及其他工贸行业
        this.productSchemeList1 = [
          {
            /*  1

第1档
300万元 */
            employeeNum: "0＜b≤10", //从业人员人数（a）
            insureLimitGrade: "第1档", //最低投保限额档次
            dutyLimitTotal: "300万元", //累计责任限额
          },
          {
            /* 2

第1档
300万元 */
            employeeNum: "10＜b≤20",
            insureLimitGrade: "第1档",
            dutyLimitTotal: "300万元",
          },
          {
            /* 3


 */
            employeeNum: "20＜b≤50",
            insureLimitGrade: "第1档",
            dutyLimitTotal: "300万元",
          },
          {
            /* 4

第2档
500万元 */
            employeeNum: "50＜b≤100",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 5

第2档
500万元 */
            employeeNum: "100＜b≤200",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 6

第2档
500万元 */
            employeeNum: "200＜b≤300",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 7

第2档
500万元 */
            employeeNum: "300＜b≤400",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 8

第2档
500万元 */
            employeeNum: "400＜b≤500",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 9


 */
            employeeNum: "500＜b≤600",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 10

第3档
1000万元 */
            employeeNum: "600＜b≤800",
            insureLimitGrade: "第3档",
            dutyLimitTotal: "1000万元",
          },
          {
            /* 11


 */
            employeeNum: "800＜b≤1000",
            insureLimitGrade: "第3档",
            dutyLimitTotal: "1000万元",
          },
          {
            /* 12

第4档
2000万元 */
            employeeNum: "1000＜b≤1200",
            insureLimitGrade: "第4档",
            dutyLimitTotal: "2000万元",
          },
          {
            /* 
13

第4档
2000万元 */
            employeeNum: "1200＜b≤1500",
            insureLimitGrade: "第4档",
            dutyLimitTotal: "2000万元",
          },
          {
            /* 14
1500＜b

 */
            employeeNum: "1500＜b",
            insureLimitGrade: "第4档",
            dutyLimitTotal: "2000万元",
          },
                   {
            /* 14
15
1500＜b
第5档
5000万元 */
            employeeNum: "1500＜b",
            insureLimitGrade: "第5档",
            dutyLimitTotal: "5000万元",
          },
        ];
      } else if ("PR8812" == product_code) {
        //非煤矿行业
        this.productSchemeList1 = [
          {
            /*  1

第1档
300万元 */
            employeeNum: "0＜b≤20", //从业人员人数（a）
            insureLimitGrade: "第1档", //最低投保限额档次
            dutyLimitTotal: "300万元", //累计责任限额
          },
          {
            /* 2
20＜b≤50
第1档
300万元 */
            employeeNum: "20＜b≤50",
            insureLimitGrade: "第1档",
            dutyLimitTotal: "300万元",
          },
          {
            /* 3
50＜b≤100
第2档
500万元 */
            employeeNum: "50＜b≤100",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 4
100＜b≤200
第2档
500万元 */
            employeeNum: "100＜b≤200",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 5
200＜b≤300
第2档
500万元 */
            employeeNum: "200＜b≤300",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 
6
300＜b≤400
第2档
500万元 */
            employeeNum: "300＜b≤400",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 7
400＜b≤500
第2档
500万元 */
            employeeNum: "400＜b≤500",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 8
500＜b≤600
第2档
500万元 */
            employeeNum: "500＜b≤600",
            insureLimitGrade: "第2档",
            dutyLimitTotal: "500万元",
          },
          {
            /* 9
600＜b≤800
第3档
1000万元 */
            employeeNum: "600＜b≤800",
            insureLimitGrade: "第3档",
            dutyLimitTotal: "1000万元",
          },
          {
            /* 10
800＜b≤1000
第3档
1000万元 */
            employeeNum: "800＜b≤1000",
            insureLimitGrade: "第3档",
            dutyLimitTotal: "1000万元",
          },
          {
            /* 11
1000＜b≤1200
第4档
2000万元 */
            employeeNum: "1000＜b≤1200",
            insureLimitGrade: "第4档",
            dutyLimitTotal: "2000万元",
          },
          {
            /* 12
1200＜b≤1500
第4档
2000万元 */
            employeeNum: "1200＜b≤1500",
            insureLimitGrade: "第4档",
            dutyLimitTotal: "2000万元",
          },
          {
            /* 13
1500＜b
第4档
2000万元 */
            employeeNum: "1500＜b",
            insureLimitGrade: "第4档",
            dutyLimitTotal: "2000万元",
          },
          {
            /* 14
1500＜b
第5档
5000万元 */
            employeeNum: "1500＜b",
            insureLimitGrade: "第5档",
            dutyLimitTotal: "5000万元",
          },
        ];
      }
    },
  },
};
</script>

<style scoped>
</style>