<template>
  <div>
    <Steps :stepNum="3" />
<Card4 />
  </div>
</template>

<script>
import Steps from "@/components/product/Steps.vue";
import Card4 from "@/components/product/Card4.vue";

export default {
  components: {
    Steps,
    Card4,
  },
};
</script>

<style scoped>
</style>