<template>
  <div>
    <Steps :stepNum="2" />
    <Card3 />
  </div>
</template>

<script>
import Card3 from "@/components/product/Card3.vue";
import Steps from "@/components/product/Steps.vue";
export default {
  components: {
    Steps,
    Card3,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>